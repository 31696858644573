.flow {
    &-list {
        width: 100%;
        tr {
            margin-bottom: 5px;
            background: $color-gray-snow;
            padding: 15px;
            display: flex;
            width: 100%;

            @include view-at(sp) {
                font-size: 14px;
                padding: 15px;
            }
            th {
                font-family: 'EB Garamond', serif;
                margin-right: 15px;
                font-size: 18px;
                display: flex;
                align-items: center;
            }
        }
    }
    &-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            padding: 5px;
            width: calc(100%/4);
            @include view-at(tab) {
                width: calc(100%/2);
            }
            @include view-at(sp) {
                width: calc(100%/1);
            }
            a {
                display: block;
                background: $color-gray-snow;
                text-align: center;
                padding: 15px;
                position: relative;
                font-size: 14px;
                &::before {
                    content: "";
                    display: block;
                    height: 5px;
                    width: 5px;
                    border-top: 1px solid black;
                    border-right: 1px solid black;
                    position: absolute;
                    top: 45%;
                    right: 20px;
                    transform: rotate(45deg);
                }
                &:hover {
                    opacity: 1;
                    background: $color-green;
                    color: white;
                    &::before {
                        border-color: white;
                    }
                }
                &.current {
                    background: $color-green;
                    color: white;
                    &::before {
                        border-color: white;
                    }
                }
            }
        }
    }
    &-schedule {
        margin: 0;
        display: block;
        position: relative;
        height: 100%;
        &::before {
            content: "";
            display: block;
            width: 4px;
            height: 100%;
            background: linear-gradient(to top, #0c8100, #a9cb03);
            position: absolute;
            top: 0;
            left: 100px;
            @include view-at(sp) {
                left: 60px;
            }
        }
        tbody {
            width: 100%;
            display: block;
        }
        tr {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            &:nth-of-type(odd) {
                background-color: $color-green-snow;
            }
            
            th {
                width: 120px;
                font-family: 'EB Garamond', serif;
                font-weight: normal;
                position: relative;
                @include view-at(sp) {
                    width: 80px;
                }
                &::before {
                    content: "●";
                    display: block;
                    color: $color-green;
                    position: absolute;
                    top: -7px;
                    right: 10px;
                }
                span {
                    position: absolute;
                    top: -7px;
                    left: 40px;
                    @include view-at(sp) {
                        left: 10px;
                    }
                }
            }
            td {
                width: calc(100% - 120px);
                padding: 15px;
                @include view-at(sp) {
                    width: calc(100% - 80px);
                    padding: 15px 10px;
                }
            }
        }
    }
}
/* --------------------------
タブ
-------------------------- */

.c-tab {
  &-nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    @include view-at(sp) {
      flex-wrap: wrap;
    }
    &-item {
      width: 200px;
      font-size: 14px;

      @include view-at(sp) {
        width: 50%;
        &:nth-last-child(1) {
          width: 100%;
        }
      }

      &:last-child {
        a {
          border-right: 1px solid $color-gray-light;
        }
      }

      a {
        background-color: $color-gray-snow;
        border: 1px solid $color-gray-light;
        border-right: 0;
        display: block;
        padding: 20px;
        text-align: center;
        text-decoration: none;
        color: $color-black;
        font-weight: $bold;

        @include view-at(sp) {
          border-right: 1px solid $color-gray-light;
        }

        &[aria-selected="true"] {
          background-color: $color-white;
          border-bottom: 1px solid $color-white;
          color: $color-black;

          @include view-at(sp) {
            background-color: $color-white;
            border-bottom: 1px solid $color-white;
            color: $color-black;
          }
        }
      }
    }
  }

  &-contents {
    &-item {
      display: none;
      background-color: $color-white;
      border-top: 0;
      padding: 40px;

      @include view-at(sp) {
        padding: 20px;
      }

      &[aria-hidden="false"] {
        display: block;
      }
    }
  }
}

.recruit-tab {
  .c-tab {
    &-nav {
      margin: -5px;
      &-item {
        width: calc(100%/5);
        padding: 0 5px;
        @include view-at(sp) {
          width: 50%;
          padding: 5px;
        }
        a {
          background-color: $color-gray-snow;
          border: 1px solid $color-gray-light;
          &[aria-selected="true"] {
            background-color: $color-green-light;
            color: $color-white;
            border-color: $color-green-light;
          }
          @include view-at(sp) {
            font-size: 12px;
            padding: 15px;
          }
        }
      }
      &.career {
        a {
          &[aria-selected="true"] {
            background: #5BA602;
            border-color: #5BA602;
          }
        }
      }
      &.part {
        a {
          &[aria-selected="true"] {
            background: $color-green;
            border-color: $color-green;
          }
        }
      }
    }
  
    &-contents {
      &-item {
        padding: 20px 0 0;
      }
    }
  }
}

.philosophy {
    &-bg {
        background: url(../images/philosophy/bg.jpg) no-repeat;
        background-size: 100%;
        background-position: bottom;
        padding-bottom: 350px;
        @include view-at(tab) {
            padding-bottom: 250px;
        }
        @include view-at(sp) {
            padding-bottom: 100px;
        }
    }
}
/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-blue-dark;
}

.u-text-important {
  color: $color-green;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}

.u-text-en {
  font-family: 'EB Garamond', serif;
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-text-lineheight2 {
  line-height: 2em;
}
.u-text-lineheight3 {
  line-height: 3em !important;
}

.u-text-x-large {
  font-size: nth($font-size, 1);
  line-height: 1.8em;
  @include view-at(tab) {
    font-size: 20px;
  }
}

.u-text-large {
  font-size: nth($font-size, 2);
  line-height: 1.8em;
  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
  }
}

.u-text-bit-large {
  font-size: nth($font-size, 3);
  line-height: 1.8em;
  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
  }
}

.u-text-default {
  font-size: nth($font-size, 4);
  line-height: 1.8em;
  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
  }
}

.u-text-small {
  font-size: nth($font-size, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
  }
}

.u-text-x-small {
  font-size: nth($font-size, 6);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
  }

  &--sp {
    @include view-at(sp) {
      font-size: nth($font-size-sp, 6);
    }
  }
}

.u-text-cap {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  font-weight: bold;
  filter: drop-shadow(0 0 2px black);
  &-wrap {
    position: relative;
  }
}




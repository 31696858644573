@charset "UTF-8";

.top {
    &-mv {
        position: relative;
        &-inner {
            width: 100%;
            max-width: 1700px;
            padding: 0 170px;
            margin: 0 auto;
            position: relative;
            @include view-at(laptop) {
                padding: 0 80px;
            }
            @include view-at(sp) {
                padding: 0;
            }
            li {
                position: relative;
                .text {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: white;
                    -ms-writing-mode: tb-rl;
                    writing-mode: vertical-rl;
                    font-size: 28px;
                    letter-spacing: 0.1em;
                    line-height: 2em;
                    padding: 80px 0;
                    @include view-at(laptop) {
                        padding: 60px 0;
                        font-size: 23px;
                    }
                    @include view-at(tab) {
                        -ms-writing-mode: horizontal-tb;
                        writing-mode:horizontal-tb;
                        padding: 0 40px 40px 0;
                        font-size: 18px;
                        position: unset;
                        display: inline-block;
                    }
                    @include view-at(sp) {
                        padding: 20px 20px 40px;
                        font-size: 15px;
                    }
                    span.en {
                        font-family: 'EB Garamond', serif;
                        font-size: 14px;
                        display: block;
                        border: none;
                        @include view-at(laptop) {
                            font-size: 12px;
                        }
                    }
                    span {
                        display: block;
                        border-left: 1px solid $color-gray-snow;
                        font-family: 'EB Garamond', serif;
                        @include view-at(tab) {
                           border: none;
                           border-bottom: 1px solid $color-gray-snow;
                        }
                    }
                }
            }
        }
        &-copy {
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            position: fixed;
            right: 80px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            font-family: 'EB Garamond', serif;
            text-align: center;
            @include view-at(laptop) {
                right: 35px;
            }
            @include view-at(sp) {
                font-size: 10px;
                right: 5px;
                z-index: 9999;
            }
        }
        .slick-dotted.slick-slider {
            margin-bottom: 0;
        }
        .slick-dots {
            bottom: 15px;
            @include view-at(sp) {
                bottom: 20px;
            }
            li {
                width: 10px;
                height: 10px;
                button {
                    width: 10px;
                    height: 10px;
                    &::before {
                        width: 10px;
                        height: 10px;
                        background: white;
                        content: '';
                        opacity: 0.5;
                    }
                }
                &.slick-active button:before {
                    opacity: 1;
                }
            }
        }
    }
    &-news {
        background: white;
        position: absolute;
        bottom: 0;
        right: 170px;
        padding: 25px;
        display: flex;
        justify-content: center;
        font-size: 12px;
        @include view-at(laptop) {
            right: 80px;
        }
        @include view-at(tab) {
            padding: 15px 25px;
        }
        @include view-at(sp) {
            right: 0;
            bottom: -25px;
            font-size: 10px;
        }
        .ttl {
            font-family: 'EB Garamond', serif;
            font-size: 16px;
            padding-right: 25px;
            border-right: 1px solid $color-gray;
            margin-right: 25px;
            @include view-at(tab) {
                font-size: 14px;
            }
             @include view-at(sp) {
                font-size: 12px;
                margin-right: 15px;
                padding-right: 15px;
            }
        }
        th {
            font-family: 'EB Garamond', serif;
            font-weight: normal;
            margin-right: 10px;
        }
    }
    &-philosophy {
        margin-top: 0;
        background: url(../images/top/bg.jpg) no-repeat;
        background-size: 100%;
        background-position: bottom center;
        padding-bottom: 20%;
    }
    &-message {
    }
    &-recruit {
        position: relative;
            overflow-x: hidden;
        .bg {
            position: absolute;
            width: 100%;
            height: 300px;
            z-index: -1;
            top: 0;
            background: $color-green-gra;
            @include view-at(sp) {
                height: 250px;
            }
        }
        &-list {
            a {
                display: block;
                border:1px solid $color-gray-light;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                background: white;
                .img {
                    width: 120px;
                    height: 100%;
                    background: $color-gray-light;
                    @include view-at(sp) {
                        width: 100%;
                        height: 100px;
                    }
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    width: calc(100% - 120px);
                    height: 100%;
                    padding: 15px 20px;
                    position: relative;
                    @include view-at(sp) {
                        width: 100%;
                        height: auto;
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-top: 1px solid $color-gray-light;
                        border-right: 1px solid $color-gray-light;
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
                .date {
                    font-size: 12px;
                    font-family: 'EB Garamond', serif;
                    position: absolute;
                    top: 0;
                    left: 0;
                    span {
                        background: $color-green-gra;
                        font-size: 10px;
                        color: white;
                        display: inline-block;
                        padding: 5px 10px;
                    }
                }
                .ttl {
                    margin-bottom: 5px;
                }
                .category {
                    span {
                        display: inline-block;
                        background: #EEF5CD;
                        padding: 2px 10px;
                        border-radius: 20px;
                        margin-bottom: 2px;
                        line-height: 1em;
                    }
                }
            }
        }
    }
    &-other {
        ul {
            li {
                a {
                    display: block;
                    padding: 50px 40px;
                    position: relative;
                    @include view-at(sp) {
                        padding: 40px 30px;
                    }
                    h2 {
                        font-size: 26px;
                        color: white;
                        margin-bottom: 0;
                        @include view-at(sp) {
                            font-size: 22px;
                        }
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 80px;
                        height: 1px;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        background: white;
                        @include view-at(sp) {
                            width: 60px;
                        }
                    }
                }
                &:nth-child(1) {
                    a {
                        background: url(../images/top/company.jpg) no-repeat;
                        background-size: cover;
                    }
                }
                &:nth-child(2) {
                    a {
                        background: url(../images/top/q&a.jpg) no-repeat;
                        background-size: cover;
                    }
                }
                &:nth-child(3) {
                    a {
                        background: url(../images/top/welfare.jpg) no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
}
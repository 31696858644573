.message {
    &-line {
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 130px;
            background: black;
            position: absolute;
            top: -130px;
            left: 80px;
            @include view-at(tab) {
                left: 50%;
                transform: translateX(-50%);
            }
            @include view-at(sp) {
                height: 50px;
                top: -70px;
            }
        }
    }
}
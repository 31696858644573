.about {
    &-line {
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 130px;
            background: black;
            position: absolute;
            top: -160px;
            left: 50%;
            transform: translateX(-50%);
            @include view-at(sp) {
                height: 50px;
                top: -70px;
            }
        }
    }
    &-nav {
        display: flex;
        flex-wrap: wrap;
        li {
            padding: 5px;
            width: calc(100%/4);
            @include view-at(tab) {
                width: calc(100%/2);
            }
            @include view-at(sp) {
                width: calc(100%/1);
            }
            a {
                display: block;
                background: $color-gray-snow;
                text-align: center;
                padding: 15px;
                position: relative;
                font-size: 14px;
                &::before {
                    content: "";
                    display: block;
                    height: 5px;
                    width: 5px;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                    position: absolute;
                    top: 45%;
                    right: 20px;
                    transform: rotate(45deg);
                }
                &:hover {
                    opacity: 1;
                    background: $color-green;
                    color: white;
                    &::before {
                        border-color: white;
                    }
                }
            }
        }
    }
    &-messege {
        position: relative;
        border-radius: 5px;
        margin-top: 30px;
        &::before {
            content: "担当者の声";
            background: white;
            border-radius: 5px;
            padding: 5px 15px;
            position: absolute;
            top: -20px;
            left:0;
            font-size: 14px;
        }
    }
    .slick-dotted.slick-slider {
        margin-bottom: 15px;
    }
    .slick-dots {
        bottom: -15px;
        li {
            width: 30px;
            height: 5px;
            button {
                width: 30px;
                height: 5px;
                &::before {
                    width: 30px;
                    height: 5px;
                    content: '';
                    background: black;
                }
            }
        }
    }
}

body {
  /* 游ゴシック体 */
  // font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;

  /* 游明朝体 */
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-weight: 400;
  color: $color-black;
}

a {
  color: $color-black;
  text-decoration: none;
  transition: all 0.5s;
  &:hover {
    opacity: 0.6;
  }
}

p {
  font-size: nth($font-size, 4);
  line-height: 1.8em;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
    margin-bottom: 10px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

.main {
  padding-top: 75px;
  @include view-at(sp) {
    margin-left: 0;
    padding-top: 60px;
  }
}


// スクロールバー
.scrolldown1{
    /*描画位置※位置は適宜調整してください*/
    position:absolute;
    left:80px;
    bottom:0;
    /*全体の高さ*/
    height:80px;
    font-size: 12px;
    font-family: 'EB Garamond', serif;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    @include view-at(laptop) {
      left: 35px;
  }
  @include view-at(sp) {
    display: none;
}
    span{
      /*描画位置*/
      position: absolute;
      left:-5px;
      top: -50px;
      /*テキストの形状*/
      font-size: 0.7rem;
      letter-spacing: 0.05em;
    }
    &::after{
      content: "";
      /*描画位置*/
      position: absolute;
      top: 0;
      /*線の形状*/
      width: 1px;
      height: 80px;
      background: $color-gray;
      /*線の動き1.4秒かけて動く。永遠にループ*/
      animation: pathmove 1.4s ease-in-out infinite;
      opacity:0;
  }
}
@keyframes pathmove{
  0%{
    height:0;
    top:0;
    opacity: 0;
  }
  30%{
    height:30px;
    opacity: 1;
  }
  100%{
    height:0;
    top:80px;
    opacity: 0;
  }
}


// ライン
.line {
  width: 1px;
  height: 120px;
  background: $color-gray;
  margin: 0 auto 40px;
  @include view-at(sp) {
    height: 80px;
  }
}


// 画像リンク
.link-img {
  display: block;
  position: relative;
  overflow: hidden;
  .more {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
      font-family: 'EB Garamond', serif;
      background: white;
      padding: 10px 40px;
  }
  img {
    transition: all 0.5s;
  }
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.1);
    }
  }
}


// 各ページMV
.page-mv {
  background: $color-green-gra;
  text-align: center;
  max-width: 1700px;
  margin: 0 170px;
  position: relative;
  height: 280px;
  @include view-at(laptop) {
    margin: 0 80px;
  }
  @include view-at(sp) {
    margin: 0;
    height: 200px;
    margin-bottom: 20px;
  }
  h2 {
    text-align: left;
    background: white;
    padding: 40px 60px;
    padding-left: 0;
    display: inline-block;
    color: black;
    position: absolute;
    top: 70px;
    left: -40px;
    @include view-at(sp) {
      top: auto;
      bottom: 0;
      left: 0;
      padding: 20px;
      padding-right: 30px;
      padding-bottom: 0;
    }
  }
  &-philosophy {
    background: url(../images/philosophy/mv.jpg) no-repeat;
    background-size: cover;
  }
  &-message {
    background: url(../images/message/mv.jpg) no-repeat;
    background-size: cover;
  }
  &-about {
    background: url(../images/about/mv.jpg) no-repeat;
    background-size: cover;
  }
}
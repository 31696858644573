.faq {
    &-list-item {
        margin:10px;
        border-bottom:1px solid #ccc;
        position:relative;
        cursor:pointer;
        text-align: left;
        @include view-at(sp) {
            margin:15px 10px;
        }
        h3 {
            padding: 15px;
            display: flex;
            @include view-at(sp) {
                padding: 10px 15px;
                line-height: 1.3em;
            }
            span {
                font-family: 'EB Garamond', serif;
                margin-right: 10px;
            }
        }
        .icon {
            position:absolute;
            top:10px;
            right:5px;
            color:#ccc;
            font-size:18px;
        }
    }
    .answer {
        padding: 5px 0px;
        margin-bottom: 15px;
        display:none;
        p {
            font-size: 14px;
        }
    }
}

/* --------------------------
color
-------------------------- */
$color-red: #e20013;
$color-red-dark: #D04255;
$color-red-light: #f8ebed;
$color-blue: #009dbf;
$color-blue-dark: #0b2b75;
$color-blue-light: #e8f5f3;
$color-gold: #dbc583;
$color-gold-light: #ede2c1;
$color-orange: #ff6c11;
$color-pink: #f8ebed;
$color-green: #0c8100;
$color-green-light: #a9cb03;
$color-green-snow:#EEF5CD;
$color-green-gra: linear-gradient(to right, #0c8100, #a9cb03);
$color-white: #fff;
$color-black: #000000;

$color-gray: #ababab;
$color-gray-light: #c5c5c5;
$color-gray-dark: #717171;
$color-gray-snow: #F5F5F5;


/* --------------------------
spacing
-------------------------- */
$space: 160px, 80px, 40px, 20px;
$space-tab: 100px, 40px, 20px, 10px;
$space-sp: 80px, 40px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 26px, 22px, 18px, 16px, 14px, 12px;
$line-height: 68px, 30px, 30px, 22px, 20px, 18px;

$font-size-sp: 26px, 20px, 18px, 16px, 14px, 12px;
$line-height-sp: 40px, 36px, 32px, 22px, 16px, 16px;

$bold: 400;
$normal: 300;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1000px;
$contents-narrow-width: 760px;
$contents-padding: 20px;
$contents-padding-sp: 15px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  header,
  footer,
  modal
);

/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  display: flex;

  li {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: $color-green;
    font-weight: bold;

    &::after {
      content: '>';
      padding: 0 1em;
    }
    
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}


.p-gnav {
  @include view-at(laptop) {
    display: none;
    background-color: $color-gray-snow;
    position: absolute;
    right: 0;
    top: 0;
    padding: 80px 40px;
    max-width: 500px;
    width: 100%;
    height: 100vh;
  }

  &-list {
    display: flex;
    justify-content: center;
    align-items: center;

    @include view-at(laptop) {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
    }

    &-item {
      padding: 10px 20px;

      @include view-at(laptop) {
        padding: 15px 0;
        width: 100%;
        text-align: left;
      }
      
      a {
        font-size: 12px;
        letter-spacing: 0.05em;
        text-decoration: none;
        line-height: 1;
        font-weight: 400;
        position: relative;
        @include view-at(sp) {
          font-size: 14px;
        }
        &::after {
          content: "";
          width: 0;
          height: 1px;
          background: $color-green-gra;
          display: block;
          position: absolute;
          bottom: -5px;
          left: 0;
          transition: all 0.5s;
        }
      }
      &.current a,& a:hover {
        opacity: 1;
        &::after {
          width: 100%;
        }
      }

      &.career,
      &.new {
        text-align: center;
        padding: 0;
        @include view-at(laptop) {
          width: 50%;
          margin-top: 40px;
        }
        a {
          color: white;
          background: $color-green-light;
          padding: 20px;
          width: 160px;
          display: block;
          font-size: 10px;
          @include view-at(laptop) {
            width: 100%;
          }
          span {
            font-family: 'EB Garamond', serif;
            font-size: 20px;
            display: block;
            margin-bottom: 5px;
          }
          &::after {
            content: none;
          }
        }
      }
      &.new {
        margin-left: 20px;
        @include view-at(laptop) {
          margin-left: 0px;
          display: block;
        }
        @include view-at(tab) {
          display: block;
          width: 100%;
        }
      }
      &.career {
        @include view-at(tab) {
          display: block;
          width: 100%;
          margin-top: 10px;
        }
        a {
          background: $color-green;
        }
      }
    }
  }
}

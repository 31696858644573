.recruit {
    &-mv {
        background: $color-green-gra;
        color: white;
        text-align: center;
        padding: 120px 0;
    }
    &-nav {
        display: flex;
        flex-wrap: wrap;
        margin: -10px;
        @include view-at(sp) {
            margin: -5px;
        }
        li {
            padding: 10px;
            width: calc(100%/3);
            @include view-at(sp) {
                width: calc(100%);
                padding: 5px;
            }
            a {
                padding: 20px;
                background: $color-green-light;
                color: white;
                display: block;
                text-align: center;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 7px;
                    border-right: 1px solid;
                    border-top: 1px solid;
                    transform: translateY(-50%) rotate(45deg);
                    position: absolute;
                    top: 50%;
                    right: 20px;
                }
            }
            &:nth-child(2) {
                a {
                    background: #5BA602;
                }
            }
            &:nth-child(3) {
                a {
                    background: $color-green;
                }
            }
        }
    }
    &-list {
        a {
            display: block;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            background: $color-gray-snow;
            padding: 30px;
            @include view-at(sp) {
                padding: 0;
            }
            .img {
                width: 180px;
                height: 100%;
                background: $color-gray-light;
                @include view-at(sp) {
                    width: 100%;
                    height: 100px;
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                width: calc(100% - 180px);
                height: 100%;
                padding: 0 30px;
                @include view-at(sp) {
                    width: 100%;
                    height: auto;
                    padding: 20px;
                    padding-bottom: 40px;
                }
            }
            .ttl {
                margin-bottom: 5px;
            }
            .category {
                span {
                    display: inline-block;
                    background: #EEF5CD;
                    padding: 2px 10px;
                    border-radius: 20px;
                    margin-bottom: 2px;
                    line-height: 1em;
                }
            }
            .more {
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 14px;
                font-family: 'EB Garamond', serif;
                background: #a9cb03;
                color: white;
                padding: 10px 40px;
                &.career {
                    background: #5BA602;
                }
                &.part {
                    background: $color-green;
                }
            }
        }
    }
    .category {
        span {
            display: inline-block;
            background: #EEF5CD;
            padding: 2px 10px;
            border-radius: 20px;
            margin-bottom: 2px;
            line-height: 1em;
        }
    }
    .img {
        &-thum {
            height: 100%;
            width: 100%;
            background: gray;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        &-yoko {
            height: 100px;
            width: 100%;
            background: gray;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.company {
    &-road {
        margin: 0;
        display: block;
        position: relative;
        height: 100%;
        tbody {
            width: 100%;
            display: block;
        }
        tr {
            width: 100%;
            th {
                font-family: 'EB Garamond', serif;
                font-weight: normal;
                position: relative;
                padding: 15px;
                width: 200px;
                vertical-align: top;
                @include view-at(sp) {
                    width: 80px;
                }
                
                span {
                    position: absolute;
                    top: -7px;
                    left: 40px;
                    @include view-at(sp) {
                        left: 10px;
                    }
                }
            }
            td {
                padding: 15px;
                width: calc(100% - 270px);
                vertical-align: top;
                @include view-at(sp) {
                    width: calc(100% - 80px);
                    padding: 15px 10px;
                }
                &:nth-last-child(2) {
                    position: relative;
                    padding-left: 30px;
                    font-family: 'EB Garamond', serif;
                    font-weight: normal;
                    width: 70px;
                    &::before {
                        content: "●";
                        display: block;
                        color: $color-green;
                        position: absolute;
                        left: 0;
                        z-index: +1;
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 100%;
                        background: $color-gray-light;
                        position: absolute;
                        top: 15px;
                        left: 7px;
                        @include view-at(sp) {
                            left: 60px;
                        }
                    }
                }
            }
        }
    } 
}

.p-footer {
  padding: 40px 0 0;
  border-top: 1px solid $color-gray-light;

  @include view-at(sp) {
    padding: 40px 0 20px;
  }

  &-logo {
    text-align: center;
    max-width: 200px;
    margin-bottom: 20px;
    margin-right: 60px;
    flex-basis: 200px;

    @include view-at(sp) {
      width: 70%;
      margin: 0 auto;
    }
  }

  &-contents {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 40px 0 40px;

    @include view-at(sp) {
      display: block;
      font-size: 13px;
    }
    .logo {
      @include view-at(sp) {
        width: 70%;
        margin: 0 auto;
      }
    }
    
  }

  &-copyright {
    font-size: 12px;
    font-family: 'EB Garamond', serif;
    text-align: center;
    padding: 10px 0;
    @include view-at(sp) {
      font-size: 10px;
     }
  }

  &-nav {
    padding-bottom: 40px;
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 12px;
      margin: 0 -30px;
      @include view-at(sp) {
        display: block;
        margin: 0;
      }
      li {
        padding: 0 30px;
        @include view-at(sp) {
          padding: 10px 30px;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 3px solid black;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          padding-right: 10px;
        }
      }
    }
  }
}

@charset "UTF-8";
/* --------------------------
color
-------------------------- */
/* --------------------------
spacing
-------------------------- */
/* --------------------------
font
-------------------------- */
/* --------------------------
contents width
-------------------------- */
/* --------------------------
z-index order
-------------------------- */
/* --------------------------
media query
-------------------------- */
/* --------------------------
h with center-line
-------------------------- */
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 300;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
  text-align: left;
}

body {
  /* 游ゴシック体 */
  /* 游明朝体 */
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-weight: 400;
  color: #000000;
}

a {
  color: #000000;
  text-decoration: none;
  transition: all 0.5s;
}

a:hover {
  opacity: 0.6;
}

p {
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 20px;
}

p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

.main {
  padding-top: 75px;
}

@media screen and (max-width: 767px) {
  .main {
    margin-left: 0;
    padding-top: 60px;
  }
}

.scrolldown1 {
  /*描画位置※位置は適宜調整してください*/
  position: absolute;
  left: 80px;
  bottom: 0;
  /*全体の高さ*/
  height: 80px;
  font-size: 12px;
  font-family: 'EB Garamond', serif;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

@media screen and (max-width: 1280px) {
  .scrolldown1 {
    left: 35px;
  }
}

@media screen and (max-width: 767px) {
  .scrolldown1 {
    display: none;
  }
}

.scrolldown1 span {
  /*描画位置*/
  position: absolute;
  left: -5px;
  top: -50px;
  /*テキストの形状*/
  font-size: 0.7rem;
  letter-spacing: 0.05em;
}

.scrolldown1::after {
  content: "";
  /*描画位置*/
  position: absolute;
  top: 0;
  /*線の形状*/
  width: 1px;
  height: 80px;
  background: #ababab;
  /*線の動き1.4秒かけて動く。永遠にループ*/
  animation: pathmove 1.4s ease-in-out infinite;
  opacity: 0;
}

@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 30px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 80px;
    opacity: 0;
  }
}

.line {
  width: 1px;
  height: 120px;
  background: #ababab;
  margin: 0 auto 40px;
}

@media screen and (max-width: 767px) {
  .line {
    height: 80px;
  }
}

.link-img {
  display: block;
  position: relative;
  overflow: hidden;
}

.link-img .more {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 14px;
  font-family: 'EB Garamond', serif;
  background: white;
  padding: 10px 40px;
}

.link-img img {
  transition: all 0.5s;
}

.link-img:hover {
  opacity: 1;
}

.link-img:hover img {
  transform: scale(1.1);
}

.page-mv {
  background: linear-gradient(to right, #0c8100, #a9cb03);
  text-align: center;
  max-width: 1700px;
  margin: 0 170px;
  position: relative;
  height: 280px;
}

@media screen and (max-width: 1280px) {
  .page-mv {
    margin: 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .page-mv {
    margin: 0;
    height: 200px;
    margin-bottom: 20px;
  }
}

.page-mv h2 {
  text-align: left;
  background: white;
  padding: 40px 60px;
  padding-left: 0;
  display: inline-block;
  color: black;
  position: absolute;
  top: 70px;
  left: -40px;
}

@media screen and (max-width: 767px) {
  .page-mv h2 {
    top: auto;
    bottom: 0;
    left: 0;
    padding: 20px;
    padding-right: 30px;
    padding-bottom: 0;
  }
}

.page-mv-philosophy {
  background: url(../images/philosophy/mv.jpg) no-repeat;
  background-size: cover;
}

.page-mv-message {
  background: url(../images/message/mv.jpg) no-repeat;
  background-size: cover;
}

.page-mv-about {
  background: url(../images/about/mv.jpg) no-repeat;
  background-size: cover;
}

/* --------------------------
パンくずナビ
-------------------------- */
.c-breadcrumb {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  display: flex;
}

.c-breadcrumb li {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #0c8100;
  font-weight: bold;
}

.c-breadcrumb li::after {
  content: '>';
  padding: 0 1em;
}

.c-breadcrumb li:last-child::after {
  content: none;
}

/* --------------------------
ボタン
-------------------------- */
.c-button {
  display: inline-block;
  width: auto;
  padding: 15px 55px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: #000000;
  background-color: #fff;
  border: 1px solid #ababab;
  position: relative;
}

.c-button:hover {
  opacity: 0.7;
  text-decoration: none;
}

.c-button--arrow {
  width: 100%;
  max-width: 400px;
  text-align: left;
  padding: 20px 30px;
  font-size: 14px;
}

.c-button--arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  width: 30px;
  height: 1px;
  background: #ababab;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.c-button--arrow::after {
  content: '';
  position: absolute;
  top: 46%;
  right: 18px;
  width: 12px;
  height: 1px;
  background: #ababab;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  -webkit-transition: all .3s;
  transition: all .3s;
}

.c-button--arrow:hover {
  background: #ababab;
  color: white;
  opacity: 1;
}

.c-button--arrow:hover::after, .c-button--arrow:hover::before {
  background: white;
}

.c-button--arrow-left {
  text-align: center;
}

.c-button--arrow-left::before {
  left: 20px;
}

.c-button--arrow-left::after {
  left: 18px;
  transform: rotate(-35deg);
}

.c-button--arrow.green {
  background: #0c8100;
  color: white;
  border-color: #0c8100;
  padding: 30px;
  max-width: 500px;
}

.c-button--arrow.green::before, .c-button--arrow.green::after {
  background: #fff;
}

.c-button--arrow.green:hover {
  opacity: 0.6;
}

.c-button--arrow.green-light {
  background: #a9cb03;
  color: white;
  border-color: #a9cb03;
  padding: 30px;
  max-width: 500px;
}

.c-button--arrow.green-light::before, .c-button--arrow.green-light::after {
  background: #fff;
}

.c-button--arrow.green-light:hover {
  opacity: 0.6;
}

.c-button--arrow.green2 {
  background: #5BA602;
  color: white;
  border-color: #5BA602;
  padding: 30px;
  max-width: 500px;
}

.c-button--arrow.green2::before, .c-button--arrow.green2::after {
  background: #fff;
}

.c-button--arrow.green2:hover {
  opacity: 0.6;
}

.c-button--contact {
  background: white;
  padding: 20px 30px;
  font-size: 14px;
  width: 50%;
  max-width: 400px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-button--contact {
    padding: 15px;
    width: 90%;
    display: block;
    margin: 0 auto;
  }
}

.c-button--disabled {
  pointer-events: none;
  background-color: #c5c5c5;
  border-color: #c5c5c5;
  color: #fff;
}

.c-button--large {
  padding: 20px;
  font-size: 18px;
  min-width: 200px;
}

.c-button--text {
  font-size: 14px;
  font-family: 'EB Garamond', serif;
}

.c-button--tel {
  background: white;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  font-family: 'EB Garamond', serif;
  font-size: 25px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-button--tel span {
  display: inline-block;
  width: 35px;
  height: 23px;
  margin-right: 10px;
  background: url(../images/common/icon-tel.png) no-repeat;
  background-size: auto 100%;
  background-position: center;
}

.c-button--form {
  background: white;
  padding: 20px 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  font-family: 'EB Garamond', serif;
  font-size: 16px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-button--form span {
  display: inline-block;
  width: 35px;
  height: 23px;
  margin-right: 10px;
  background: url(../images/common/icon-form.png) no-repeat;
  background-size: auto 100%;
  background-position: center;
}

.c-button--link {
  position: relative;
  border-color: #0c8100;
  font-size: 14px;
}

.c-button--link::after {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #0c8100;
  border-bottom: 1px solid #0c8100;
  display: block;
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  top: 50%;
  right: 10px;
}

.c-button--next {
  position: relative;
  border-color: #0c8100;
  background: #0c8100;
  color: white;
  font-size: 14px;
}

.c-button--next::after {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  display: block;
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  top: 50%;
  right: 10px;
}

/* --------------------------
フォーム
-------------------------- */
.c-form-text {
  border: 1px solid #c5c5c5;
  width: 100%;
  padding: 3px 15px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
}

.c-form-textarea {
  border: 1px solid #c5c5c5;
  width: 100%;
  padding: 8px 5px;
  height: 100px;
  border-radius: 3px;
}

.c-form-checkbox-input {
  display: none;
}

.c-form-checkbox-input:checked + .c-form-checkbox-span::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 12px;
  transform: rotate(40deg);
  border-bottom: 3px solid #e20013;
  border-right: 3px solid #e20013;
}

.c-form-checkbox-span {
  padding-left: 28px;
  position: relative;
}

.c-form-checkbox-span::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #ababab;
  background-color: #fff;
}

.c-form-radio-input {
  display: none;
}

.c-form-radio-input:checked + .c-form-radio-span::after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e20013;
}

.c-form-radio-span {
  padding-left: 20px;
  position: relative;
}

.c-form-radio-span::before {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #c5c5c5;
  background-color: #fff;
}

.c-form-select {
  border: 1px solid #c5c5c5;
  width: 100%;
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
}

/* --------------------------
見出し
-------------------------- */
.c-h1 {
  margin-top: 190px;
  color: #000000;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: 26px;
  line-height: 68px;
}

@media screen and (max-width: 767px) {
  .c-h1 {
    font-size: 26px;
    line-height: 40px;
    padding: 15px;
    margin-top: 110px;
  }
}

.c-h1 small {
  display: block;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 3px;
}

.c-h2 {
  font-family: 'EB Garamond', serif;
  margin: 0 auto 40px;
  font-size: 35px;
}

@media screen and (max-width: 1023px) {
  .c-h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

.c-h2 span {
  font-size: 12px;
  margin-top: 10px;
  display: block;
}

.c-h2-text {
  font-size: 28px;
  margin-bottom: 40px;
  line-height: 2;
}

@media screen and (max-width: 1023px) {
  .c-h2-text {
    font-size: 18px;
    line-height: 2em;
    margin-bottom: 20px;
  }
}

.c-h3 {
  font-size: 20px;
  line-height: 1.8em;
  letter-spacing: 0.08em;
  font-weight: 300;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .c-h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.c-h3--border {
  border-bottom: 1px solid #c5c5c5;
  padding-bottom: 10px;
}

.c-h3--dot {
  display: flex;
  align-items: center;
  line-height: 1.3em;
}

.c-h3--dot::before {
  content: "●";
  color: #0c8100;
  font-size: 80%;
  margin-right: 5px;
  display: inline-block;
}

.c-h4 {
  font-size: 18px;
  letter-spacing: 0.08em;
  font-weight: 400;
  margin-bottom: 20px;
}

.c-h4--border {
  border-left: 5px solid #0c8100;
  padding-left: 10px;
}

@media screen and (max-width: 767px) {
  .c-h4 {
    font-size: 16px;
  }
}

.c-h5 {
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: 0.08em;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .c-h5 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.c-h6 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.08em;
  font-weight: 400;
  margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .c-h6 {
    font-size: 12px;
    line-height: 16px;
  }
}

.c-h--center-line {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.c-h--center-line::before, .c-h--center-line::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #4f574d;
  display: block;
}

.c-h--center-line::before {
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .c-h--center-line::before {
    margin-right: 15px;
  }
}

.c-h--center-line::after {
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  .c-h--center-line::after {
    margin-left: 15px;
  }
}

/* --------------------------
ラベル
-------------------------- */
.c-label {
  display: inline-block;
  padding: 5px 15px;
  line-height: 1;
  background-color: #c5c5c5;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
}

.c-label--blue-dark {
  background-color: #0b2b75;
}

.c-label--red {
  background-color: #e20013;
}

.c-label--blue {
  background-color: #009dbf;
}

.c-label--green {
  background-color: #0c8100;
}

.c-label--large {
  padding: 6px 12px;
  font-size: 16px;
}

.c-label--small {
  padding: 5px 8px;
  font-size: 12px;
}

/* --------------------------
リンク
-------------------------- */
.c-link {
  color: #009dbf;
}

.c-link--block {
  display: block;
  text-decoration: none;
}

.c-link--block img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-link--none {
  text-decoration: none;
}

/* --------------------------
リスト
-------------------------- */
.c-list {
  font-size: 16px;
}

.c-list > li {
  margin-bottom: 5px;
  line-height: 24px;
  position: relative;
}

.c-list > li:last-child {
  margin-bottom: 0;
}

.c-list--point {
  margin-bottom: 1em;
}

.c-list--point > li {
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 32px;
  position: relative;
}

.c-list--point > li::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 3px;
  display: inline-block;
  background-color: #000000;
  position: absolute;
  top: 14px;
  left: 0;
}

.c-list--point > li:last-child {
  margin-bottom: 0;
}

.c-list--link li {
  padding-left: 15px;
  margin-bottom: 5px;
  line-height: 24px;
  position: relative;
}

.c-list--link li::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6.9px;
  border-color: transparent transparent transparent #e20013;
  position: absolute;
  top: 8px;
  left: 2px;
}

.c-list--link li a {
  text-decoration: none;
}

.c-list--link li a:hover {
  text-decoration: underline;
}

.c-list--horizontal {
  display: flex;
  align-items: center;
}

.c-list--horizontal li {
  margin-left: 25px;
  margin-bottom: 0;
}

.c-list--horizontal li:first-child {
  margin-left: 0;
}

.c-list--border li {
  background-position: left 16px;
  padding: 14px;
  border-bottom: 1px dashed #d4d4d4;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 1;
}

.c-list--border li:last-child {
  border-bottom: 0;
}

.c-list--border li a {
  color: #000000;
  text-decoration: none;
}

.c-list--border li a:hover {
  color: #009dbf;
}

.c-list--order {
  padding-left: 20px;
  margin-bottom: 1em;
}

.c-list--order > li {
  margin-bottom: 5px;
  line-height: 32px;
  position: relative;
}

.c-list--order > li:last-child {
  margin-bottom: 0;
}

.c-list--note li {
  font-size: 13px;
  color: #8d8d8d;
  line-height: 1.5;
}

.c-list--note li::before {
  content: '※';
  margin-right: 3px;
}

/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */
.c-menu {
  display: none;
}

@media screen and (max-width: 1280px) {
  .c-menu {
    position: absolute;
    right: 20px;
    top: 25px;
    display: block;
    z-index: +1;
  }
}

@media screen and (max-width: 767px) {
  .c-menu {
    top: 20px;
  }
}

.c-menu-trigger,
.c-menu-trigger span {
  display: inline-block;
  transition: all .2s;
  box-sizing: border-box;
}

.c-menu-trigger {
  position: relative;
  width: 30px;
  height: 22px;
}

.c-menu-trigger-label {
  display: block;
  font-size: 8px;
  letter-spacing: 1.1px;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin: auto;
  text-align: center;
  width: 100%;
  background-color: transparent;
  height: auto;
  color: #fff;
}

.c-menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000000;
}

.c-menu-trigger span:nth-of-type(1) {
  top: 0;
}

.c-menu-trigger span:nth-of-type(2) {
  top: 10px;
}

.c-menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.c-menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(10px) translateX(1px) rotate(-45deg);
  transform: translateY(10px) translateX(1px) rotate(-45deg);
}

.c-menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.c-menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-11px) translateX(1px) rotate(45deg);
  transform: translateY(-11px) translateX(1px) rotate(45deg);
}

.c-menu-recruit {
  right: 20px;
}

/* --------------------------
パネル
-------------------------- */
.c-panel {
  background-color: #F5F5F5;
}

.c-panel--border {
  border: 1px solid #c5c5c5;
  background-color: #fff;
}

.c-panel--white {
  background: white;
}

.c-panel-contents {
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .c-panel-contents {
    padding: 20px;
  }
}

.c-panel-contents--middle {
  padding: 20px;
}

.c-panel-contents--narrow {
  padding: 15px;
}

/* --------------------------
セクション
-------------------------- */
@media screen and (min-width: 768px) {
  .c-section {
    margin: 160px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section {
    margin: 80px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--middle {
    margin: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--middle {
    margin: 40px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--narrow {
    margin: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--narrow {
    margin: 20px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg {
    padding: 160px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg {
    padding: 80px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--middle {
    padding: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg--middle {
    padding: 40px 0;
  }
}

@media screen and (min-width: 768px) {
  .c-section--bg--narrow {
    padding: 40px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-section--bg--narrow {
    padding: 20px 0;
  }
}

.c-section-container {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .c-section-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1023px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-section-container {
    max-width: 100%;
    padding: 0 15px;
  }
}

.c-section-container-inner {
  max-width: 750px;
  margin: auto;
}

/* --------------------------
タブ
-------------------------- */
.c-tab-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .c-tab-nav {
    flex-wrap: wrap;
  }
}

.c-tab-nav-item {
  width: 200px;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .c-tab-nav-item {
    width: 50%;
  }
  .c-tab-nav-item:nth-last-child(1) {
    width: 100%;
  }
}

.c-tab-nav-item:last-child a {
  border-right: 1px solid #c5c5c5;
}

.c-tab-nav-item a {
  background-color: #F5F5F5;
  border: 1px solid #c5c5c5;
  border-right: 0;
  display: block;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  color: #000000;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .c-tab-nav-item a {
    border-right: 1px solid #c5c5c5;
  }
}

.c-tab-nav-item a[aria-selected="true"] {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  color: #000000;
}

@media screen and (max-width: 767px) {
  .c-tab-nav-item a[aria-selected="true"] {
    background-color: #fff;
    border-bottom: 1px solid #fff;
    color: #000000;
  }
}

.c-tab-contents-item {
  display: none;
  background-color: #fff;
  border-top: 0;
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .c-tab-contents-item {
    padding: 20px;
  }
}

.c-tab-contents-item[aria-hidden="false"] {
  display: block;
}

.recruit-tab .c-tab-nav {
  margin: -5px;
}

.recruit-tab .c-tab-nav-item {
  width: calc(100%/5);
  padding: 0 5px;
}

@media screen and (max-width: 767px) {
  .recruit-tab .c-tab-nav-item {
    width: 50%;
    padding: 5px;
  }
}

.recruit-tab .c-tab-nav-item a {
  background-color: #F5F5F5;
  border: 1px solid #c5c5c5;
}

.recruit-tab .c-tab-nav-item a[aria-selected="true"] {
  background-color: #a9cb03;
  color: #fff;
  border-color: #a9cb03;
}

@media screen and (max-width: 767px) {
  .recruit-tab .c-tab-nav-item a {
    font-size: 12px;
    padding: 15px;
  }
}

.recruit-tab .c-tab-nav.career a[aria-selected="true"] {
  background: #5BA602;
  border-color: #5BA602;
}

.recruit-tab .c-tab-nav.part a[aria-selected="true"] {
  background: #0c8100;
  border-color: #0c8100;
}

.recruit-tab .c-tab-contents-item {
  padding: 20px 0 0;
}

/* --------------------------
テーブル
-------------------------- */
.c-table {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
}

@media screen and (max-width: 767px) {
  .c-table thead tr {
    border-bottom: 0;
  }
}

.c-table thead th {
  padding: 25px;
  line-height: 1.5;
  font-weight: 400;
  width: 30%;
  background-color: #F5F5F5;
  vertical-align: top;
  border-bottom: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .c-table thead th {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.c-table thead td {
  font-weight: 400;
  text-align: center;
  padding: 20px 30px;
  border-bottom: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .c-table thead td {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-table tbody tr {
    display: block;
    border-bottom: 0;
    margin-bottom: 10px;
  }
}

.c-table tbody th {
  padding: 25px;
  line-height: 1.5;
  font-weight: 400;
  width: 25%;
  vertical-align: top;
  border-bottom: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .c-table tbody th {
    display: block;
    width: 100%;
    padding: 10px;
    min-height: auto;
    font-weight: bold;
    font-size: 15px;
  }
}

.c-table tbody td {
  padding: 20px 30px;
  border-bottom: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .c-table tbody td {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    font-size: 14px;
  }
}

.c-table--narrow {
  border-color: #c5c5c5;
}

.c-table--narrow tbody tr {
  border-color: #c5c5c5;
}

.c-table--narrow tbody th {
  padding: 8px 10px;
  line-height: 1.5;
  font-weight: normal;
  width: 120px;
  font-weight: 400;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .c-table--narrow tbody th {
    padding: 8px;
    width: 80px;
  }
}

.c-table--narrow tbody td {
  padding: 8px 10px 8px 20px;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .c-table--narrow tbody td {
    padding: 8px 10px 8px 15px;
  }
}

.c-table--welfare {
  border: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .c-table--welfare tr {
    margin: 0 !important;
    border-bottom: 1px solid #c5c5c5 !important;
  }
  .c-table--welfare tr:nth-last-child(1) {
    border-bottom: none !important;
  }
}

.c-table--welfare tr th {
  padding: 10px;
  background: #EEF5CD;
  border-right: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .c-table--welfare tr th {
    border-right: none;
    padding: 15px;
    text-align: center;
  }
}

.c-table--welfare tr td {
  padding: 10px;
}

.c-table--welfare tr td:nth-last-child(2) {
  background: #F5F5F5;
  border-right: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .c-table--welfare tr td:nth-last-child(2) {
    border-right: none;
  }
}

.c-table--entry tr th {
  width: 250px;
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  .c-table--entry tr th {
    padding-bottom: 0;
  }
}

.c-table--entry tr th span {
  font-size: 60%;
  color: red;
  vertical-align: inherit;
  padding-left: 5px;
}

.c-table--entry tr td {
  border-bottom: none;
}

/* --------------------------
テキスト整列
-------------------------- */
.u-align-left {
  text-align: left;
}

.u-align-right {
  text-align: right;
}

.u-align-center {
  text-align: center;
}

.u-align-justify {
  text-align: justify;
  word-wrap: break-word;
}

@media screen and (min-width: 768px) {
  .u-align-left--pc {
    text-align: left;
  }
  .u-align-right--pc {
    text-align: right;
  }
  .u-align-center--pc {
    text-align: center;
  }
}

@media screen and (max-width: 1280px) {
  .u-align-left--laptop {
    text-align: left;
  }
  .u-align-right--laptop {
    text-align: right;
  }
  .u-align-center--laptop {
    text-align: center;
  }
}

@media screen and (max-width: 1023px) {
  .u-align-left--tab {
    text-align: left;
  }
  .u-align-right--tab {
    text-align: right;
  }
  .u-align-center--tab {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .u-align-left--sp {
    text-align: left;
  }
  .u-align-right--sp {
    text-align: right;
  }
  .u-align-center--sp {
    text-align: center;
  }
}

/* --------------------------
背景色
-------------------------- */
.u-bg-light {
  background-color: #F5F5F5;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-green {
  background-color: #a9cb03;
}

.u-bg-blue {
  background-color: #009dbf;
}

.u-bg-gold {
  background-color: #dbc583;
}

.u-bg-blue-dark {
  background-color: #0b2b75;
}

.u-bg-red {
  background-color: #f8ebed;
}

/* --------------------------
枠線
-------------------------- */
.u-border-bottom {
  border-bottom: 1px solid #e20013;
}

.u-border-top {
  border-top: 1px solid #e20013;
}

.u-border-right {
  border-right: 1px solid #e20013;
}

.u-border-left {
  border-left: 1px solid #e20013;
}

.u-border--white {
  border-color: #fff;
}

/* --------------------------
Float解除
-------------------------- */
.u-clearfix::after {
  content: "";
  clear: both;
  font-size: 0;
  height: 0;
  display: block;
  visibility: hidden;
}

/* --------------------------
グリッド
-------------------------- */
.u-row {
  margin: -30px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .u-row {
    margin: -10px;
  }
}

.u-row-align-center {
  align-items: center;
}

.u-row-justify-center {
  justify-content: center;
}

.u-row-reverse {
  flex-direction: row-reverse;
}

.u-row--nomargin {
  margin: 0;
}

.u-row--middle {
  margin: 0 -20px;
}

.u-row--narrow {
  margin: 0 -10px;
}

.u-col-1 {
  width: 8.3333333333%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-1 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-1 {
  margin-left: 8.3333333333%;
}

.u-row--nomargin .u-col-1 {
  padding: 0;
}

.u-row--narrow .u-col-1 {
  padding: 10px;
}

.u-row--middle .u-col-1 {
  padding: 20px;
}

.u-col-2 {
  width: 16.6666666667%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-2 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-2 {
  margin-left: 16.6666666667%;
}

.u-row--nomargin .u-col-2 {
  padding: 0;
}

.u-row--narrow .u-col-2 {
  padding: 10px;
}

.u-row--middle .u-col-2 {
  padding: 20px;
}

.u-col-3 {
  width: 25%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-3 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-3 {
  margin-left: 25%;
}

.u-row--nomargin .u-col-3 {
  padding: 0;
}

.u-row--narrow .u-col-3 {
  padding: 10px;
}

.u-row--middle .u-col-3 {
  padding: 20px;
}

.u-col-4 {
  width: 33.3333333333%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-4 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-4 {
  margin-left: 33.3333333333%;
}

.u-row--nomargin .u-col-4 {
  padding: 0;
}

.u-row--narrow .u-col-4 {
  padding: 10px;
}

.u-row--middle .u-col-4 {
  padding: 20px;
}

.u-col-5 {
  width: 41.6666666667%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-5 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-5 {
  margin-left: 41.6666666667%;
}

.u-row--nomargin .u-col-5 {
  padding: 0;
}

.u-row--narrow .u-col-5 {
  padding: 10px;
}

.u-row--middle .u-col-5 {
  padding: 20px;
}

.u-col-6 {
  width: 50%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-6 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-6 {
  margin-left: 50%;
}

.u-row--nomargin .u-col-6 {
  padding: 0;
}

.u-row--narrow .u-col-6 {
  padding: 10px;
}

.u-row--middle .u-col-6 {
  padding: 20px;
}

.u-col-7 {
  width: 58.3333333333%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-7 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-7 {
  margin-left: 58.3333333333%;
}

.u-row--nomargin .u-col-7 {
  padding: 0;
}

.u-row--narrow .u-col-7 {
  padding: 10px;
}

.u-row--middle .u-col-7 {
  padding: 20px;
}

.u-col-8 {
  width: 66.6666666667%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-8 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-8 {
  margin-left: 66.6666666667%;
}

.u-row--nomargin .u-col-8 {
  padding: 0;
}

.u-row--narrow .u-col-8 {
  padding: 10px;
}

.u-row--middle .u-col-8 {
  padding: 20px;
}

.u-col-9 {
  width: 75%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-9 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-9 {
  margin-left: 75%;
}

.u-row--nomargin .u-col-9 {
  padding: 0;
}

.u-row--narrow .u-col-9 {
  padding: 10px;
}

.u-row--middle .u-col-9 {
  padding: 20px;
}

.u-col-10 {
  width: 83.3333333333%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-10 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-10 {
  margin-left: 83.3333333333%;
}

.u-row--nomargin .u-col-10 {
  padding: 0;
}

.u-row--narrow .u-col-10 {
  padding: 10px;
}

.u-row--middle .u-col-10 {
  padding: 20px;
}

.u-col-11 {
  width: 91.6666666667%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-11 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-11 {
  margin-left: 91.6666666667%;
}

.u-row--nomargin .u-col-11 {
  padding: 0;
}

.u-row--narrow .u-col-11 {
  padding: 10px;
}

.u-row--middle .u-col-11 {
  padding: 20px;
}

.u-col-12 {
  width: 100%;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .u-col-12 {
    padding: 10px;
  }
}

.u-col-offset-0 {
  margin-left: 0;
}

.u-col-offset-12 {
  margin-left: 100%;
}

.u-row--nomargin .u-col-12 {
  padding: 0;
}

.u-row--narrow .u-col-12 {
  padding: 10px;
}

.u-row--middle .u-col-12 {
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .u-col-1--pc {
    width: 8.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-1--pc {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-1--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-1--pc {
    padding: 20px;
  }
  .u-col-2--pc {
    width: 16.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-2--pc {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-2--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-2--pc {
    padding: 20px;
  }
  .u-col-3--pc {
    width: 25%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-3--pc {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-3--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-3--pc {
    padding: 20px;
  }
  .u-col-4--pc {
    width: 33.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-4--pc {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-4--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-4--pc {
    padding: 20px;
  }
  .u-col-5--pc {
    width: 41.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-5--pc {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-5--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-5--pc {
    padding: 20px;
  }
  .u-col-6--pc {
    width: 50%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-6--pc {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-6--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-6--pc {
    padding: 20px;
  }
  .u-col-7--pc {
    width: 58.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-7--pc {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-7--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-7--pc {
    padding: 20px;
  }
  .u-col-8--pc {
    width: 66.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-8--pc {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-8--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-8--pc {
    padding: 20px;
  }
  .u-col-9--pc {
    width: 75%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-9--pc {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-9--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-9--pc {
    padding: 20px;
  }
  .u-col-10--pc {
    width: 83.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-10--pc {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-10--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-10--pc {
    padding: 20px;
  }
  .u-col-11--pc {
    width: 91.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-11--pc {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-11--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-11--pc {
    padding: 20px;
  }
  .u-col-12--pc {
    width: 100%;
    padding: 30px;
  }
  .u-col-offset-0--pc {
    margin-left: 0;
  }
  .u-col-offset-12--pc {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--pc {
    padding: 0;
  }
  .u-row--narrow .u-col-12--pc {
    padding: 10px;
  }
  .u-row--middle .u-col-12--pc {
    padding: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .u-col-1--laptop {
    width: 8.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-1--laptop {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-1--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-1--laptop {
    padding: 20px;
  }
  .u-col-2--laptop {
    width: 16.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-2--laptop {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-2--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-2--laptop {
    padding: 20px;
  }
  .u-col-3--laptop {
    width: 25%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-3--laptop {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-3--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-3--laptop {
    padding: 20px;
  }
  .u-col-4--laptop {
    width: 33.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-4--laptop {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-4--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-4--laptop {
    padding: 20px;
  }
  .u-col-5--laptop {
    width: 41.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-5--laptop {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-5--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-5--laptop {
    padding: 20px;
  }
  .u-col-6--laptop {
    width: 50%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-6--laptop {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-6--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-6--laptop {
    padding: 20px;
  }
  .u-col-7--laptop {
    width: 58.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-7--laptop {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-7--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-7--laptop {
    padding: 20px;
  }
  .u-col-8--laptop {
    width: 66.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-8--laptop {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-8--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-8--laptop {
    padding: 20px;
  }
  .u-col-9--laptop {
    width: 75%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-9--laptop {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-9--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-9--laptop {
    padding: 20px;
  }
  .u-col-10--laptop {
    width: 83.3333333333%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-10--laptop {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-10--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-10--laptop {
    padding: 20px;
  }
  .u-col-11--laptop {
    width: 91.6666666667%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-11--laptop {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-11--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-11--laptop {
    padding: 20px;
  }
  .u-col-12--laptop {
    width: 100%;
    padding: 30px;
  }
  .u-col-offset-0--laptop {
    margin-left: 0;
  }
  .u-col-offset-12--laptop {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--laptop {
    padding: 0;
  }
  .u-row--narrow .u-col-12--laptop {
    padding: 10px;
  }
  .u-row--middle .u-col-12--laptop {
    padding: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .u-col-1--tab {
    width: 8.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-1--tab {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-1--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-1--tab {
    padding: 20px;
  }
  .u-col-2--tab {
    width: 16.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-2--tab {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-2--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-2--tab {
    padding: 20px;
  }
  .u-col-3--tab {
    width: 25%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-3--tab {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-3--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-3--tab {
    padding: 20px;
  }
  .u-col-4--tab {
    width: 33.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-4--tab {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-4--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-4--tab {
    padding: 20px;
  }
  .u-col-5--tab {
    width: 41.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-5--tab {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-5--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-5--tab {
    padding: 20px;
  }
  .u-col-6--tab {
    width: 50%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-6--tab {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-6--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-6--tab {
    padding: 20px;
  }
  .u-col-7--tab {
    width: 58.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-7--tab {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-7--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-7--tab {
    padding: 20px;
  }
  .u-col-8--tab {
    width: 66.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-8--tab {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-8--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-8--tab {
    padding: 20px;
  }
  .u-col-9--tab {
    width: 75%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-9--tab {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-9--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-9--tab {
    padding: 20px;
  }
  .u-col-10--tab {
    width: 83.3333333333%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-10--tab {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-10--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-10--tab {
    padding: 20px;
  }
  .u-col-11--tab {
    width: 91.6666666667%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-11--tab {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-11--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-11--tab {
    padding: 20px;
  }
  .u-col-12--tab {
    width: 100%;
    padding: 10px;
  }
  .u-col-offset-0--tab {
    margin-left: 0;
  }
  .u-col-offset-12--tab {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--tab {
    padding: 0;
  }
  .u-row--narrow .u-col-12--tab {
    padding: 10px;
  }
  .u-row--middle .u-col-12--tab {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-1--sp {
    width: 8.3333333333%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-1--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-1--sp {
    margin-left: 8.3333333333%;
  }
  .u-row--nomargin .u-col-1--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-1--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-1--sp {
    padding: 20px;
  }
  .u-col-2--sp {
    width: 16.6666666667%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-2--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-2--sp {
    margin-left: 16.6666666667%;
  }
  .u-row--nomargin .u-col-2--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-2--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-2--sp {
    padding: 20px;
  }
  .u-col-3--sp {
    width: 25%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-3--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-3--sp {
    margin-left: 25%;
  }
  .u-row--nomargin .u-col-3--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-3--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-3--sp {
    padding: 20px;
  }
  .u-col-4--sp {
    width: 33.3333333333%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-4--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-4--sp {
    margin-left: 33.3333333333%;
  }
  .u-row--nomargin .u-col-4--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-4--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-4--sp {
    padding: 20px;
  }
  .u-col-5--sp {
    width: 41.6666666667%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-5--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-5--sp {
    margin-left: 41.6666666667%;
  }
  .u-row--nomargin .u-col-5--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-5--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-5--sp {
    padding: 20px;
  }
  .u-col-6--sp {
    width: 50%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-6--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-6--sp {
    margin-left: 50%;
  }
  .u-row--nomargin .u-col-6--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-6--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-6--sp {
    padding: 20px;
  }
  .u-col-7--sp {
    width: 58.3333333333%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-7--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-7--sp {
    margin-left: 58.3333333333%;
  }
  .u-row--nomargin .u-col-7--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-7--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-7--sp {
    padding: 20px;
  }
  .u-col-8--sp {
    width: 66.6666666667%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-8--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-8--sp {
    margin-left: 66.6666666667%;
  }
  .u-row--nomargin .u-col-8--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-8--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-8--sp {
    padding: 20px;
  }
  .u-col-9--sp {
    width: 75%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-9--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-9--sp {
    margin-left: 75%;
  }
  .u-row--nomargin .u-col-9--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-9--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-9--sp {
    padding: 20px;
  }
  .u-col-10--sp {
    width: 83.3333333333%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-10--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-10--sp {
    margin-left: 83.3333333333%;
  }
  .u-row--nomargin .u-col-10--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-10--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-10--sp {
    padding: 20px;
  }
  .u-col-11--sp {
    width: 91.6666666667%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-11--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-11--sp {
    margin-left: 91.6666666667%;
  }
  .u-row--nomargin .u-col-11--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-11--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-11--sp {
    padding: 20px;
  }
  .u-col-12--sp {
    width: 100%;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .u-col-12--sp {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .u-col-offset-0--sp {
    margin-left: 0;
  }
  .u-col-offset-12--sp {
    margin-left: 100%;
  }
  .u-row--nomargin .u-col-12--sp {
    padding: 0;
  }
  .u-row--narrow .u-col-12--sp {
    padding: 10px;
  }
  .u-row--middle .u-col-12--sp {
    padding: 20px;
  }
}

/* --------------------------
非表示切り替え
-------------------------- */
@media screen and (min-width: 768px) {
  .u-hide--pc {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .u-hide--tab {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .u-hide--laptop {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .u-hide--sp {
    display: none;
  }
}

/* --------------------------
margin、padding指定
-------------------------- */
.u-mt0 {
  margin-top: 0px !important;
}

.u-mr0 {
  margin-right: 0px !important;
}

.u-ml0 {
  margin-left: 0px !important;
}

.u-mb0 {
  margin-bottom: 0px !important;
}

.u-pt0 {
  padding-top: 0px !important;
}

.u-pr0 {
  padding-right: 0px !important;
}

.u-pl0 {
  padding-left: 0px !important;
}

.u-pb0 {
  padding-bottom: 0px !important;
}

.u-mt5 {
  margin-top: 5px !important;
}

.u-mr5 {
  margin-right: 5px !important;
}

.u-ml5 {
  margin-left: 5px !important;
}

.u-mb5 {
  margin-bottom: 5px !important;
}

.u-pt5 {
  padding-top: 5px !important;
}

.u-pr5 {
  padding-right: 5px !important;
}

.u-pl5 {
  padding-left: 5px !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}

.u-mt10 {
  margin-top: 10px !important;
}

.u-mr10 {
  margin-right: 10px !important;
}

.u-ml10 {
  margin-left: 10px !important;
}

.u-mb10 {
  margin-bottom: 10px !important;
}

.u-pt10 {
  padding-top: 10px !important;
}

.u-pr10 {
  padding-right: 10px !important;
}

.u-pl10 {
  padding-left: 10px !important;
}

.u-pb10 {
  padding-bottom: 10px !important;
}

.u-mt15 {
  margin-top: 15px !important;
}

.u-mr15 {
  margin-right: 15px !important;
}

.u-ml15 {
  margin-left: 15px !important;
}

.u-mb15 {
  margin-bottom: 15px !important;
}

.u-pt15 {
  padding-top: 15px !important;
}

.u-pr15 {
  padding-right: 15px !important;
}

.u-pl15 {
  padding-left: 15px !important;
}

.u-pb15 {
  padding-bottom: 15px !important;
}

.u-mt20 {
  margin-top: 20px !important;
}

.u-mr20 {
  margin-right: 20px !important;
}

.u-ml20 {
  margin-left: 20px !important;
}

.u-mb20 {
  margin-bottom: 20px !important;
}

.u-pt20 {
  padding-top: 20px !important;
}

.u-pr20 {
  padding-right: 20px !important;
}

.u-pl20 {
  padding-left: 20px !important;
}

.u-pb20 {
  padding-bottom: 20px !important;
}

.u-mt25 {
  margin-top: 25px !important;
}

.u-mr25 {
  margin-right: 25px !important;
}

.u-ml25 {
  margin-left: 25px !important;
}

.u-mb25 {
  margin-bottom: 25px !important;
}

.u-pt25 {
  padding-top: 25px !important;
}

.u-pr25 {
  padding-right: 25px !important;
}

.u-pl25 {
  padding-left: 25px !important;
}

.u-pb25 {
  padding-bottom: 25px !important;
}

.u-mt30 {
  margin-top: 30px !important;
}

.u-mr30 {
  margin-right: 30px !important;
}

.u-ml30 {
  margin-left: 30px !important;
}

.u-mb30 {
  margin-bottom: 30px !important;
}

.u-pt30 {
  padding-top: 30px !important;
}

.u-pr30 {
  padding-right: 30px !important;
}

.u-pl30 {
  padding-left: 30px !important;
}

.u-pb30 {
  padding-bottom: 30px !important;
}

.u-mt35 {
  margin-top: 35px !important;
}

.u-mr35 {
  margin-right: 35px !important;
}

.u-ml35 {
  margin-left: 35px !important;
}

.u-mb35 {
  margin-bottom: 35px !important;
}

.u-pt35 {
  padding-top: 35px !important;
}

.u-pr35 {
  padding-right: 35px !important;
}

.u-pl35 {
  padding-left: 35px !important;
}

.u-pb35 {
  padding-bottom: 35px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mr40 {
  margin-right: 40px !important;
}

.u-ml40 {
  margin-left: 40px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-pt40 {
  padding-top: 40px !important;
}

.u-pr40 {
  padding-right: 40px !important;
}

.u-pl40 {
  padding-left: 40px !important;
}

.u-pb40 {
  padding-bottom: 40px !important;
}

.u-mt45 {
  margin-top: 45px !important;
}

.u-mr45 {
  margin-right: 45px !important;
}

.u-ml45 {
  margin-left: 45px !important;
}

.u-mb45 {
  margin-bottom: 45px !important;
}

.u-pt45 {
  padding-top: 45px !important;
}

.u-pr45 {
  padding-right: 45px !important;
}

.u-pl45 {
  padding-left: 45px !important;
}

.u-pb45 {
  padding-bottom: 45px !important;
}

.u-mt50 {
  margin-top: 50px !important;
}

.u-mr50 {
  margin-right: 50px !important;
}

.u-ml50 {
  margin-left: 50px !important;
}

.u-mb50 {
  margin-bottom: 50px !important;
}

.u-pt50 {
  padding-top: 50px !important;
}

.u-pr50 {
  padding-right: 50px !important;
}

.u-pl50 {
  padding-left: 50px !important;
}

.u-pb50 {
  padding-bottom: 50px !important;
}

.u-mt55 {
  margin-top: 55px !important;
}

.u-mr55 {
  margin-right: 55px !important;
}

.u-ml55 {
  margin-left: 55px !important;
}

.u-mb55 {
  margin-bottom: 55px !important;
}

.u-pt55 {
  padding-top: 55px !important;
}

.u-pr55 {
  padding-right: 55px !important;
}

.u-pl55 {
  padding-left: 55px !important;
}

.u-pb55 {
  padding-bottom: 55px !important;
}

.u-mt60 {
  margin-top: 60px !important;
}

.u-mr60 {
  margin-right: 60px !important;
}

.u-ml60 {
  margin-left: 60px !important;
}

.u-mb60 {
  margin-bottom: 60px !important;
}

.u-pt60 {
  padding-top: 60px !important;
}

.u-pr60 {
  padding-right: 60px !important;
}

.u-pl60 {
  padding-left: 60px !important;
}

.u-pb60 {
  padding-bottom: 60px !important;
}

.u-mt65 {
  margin-top: 65px !important;
}

.u-mr65 {
  margin-right: 65px !important;
}

.u-ml65 {
  margin-left: 65px !important;
}

.u-mb65 {
  margin-bottom: 65px !important;
}

.u-pt65 {
  padding-top: 65px !important;
}

.u-pr65 {
  padding-right: 65px !important;
}

.u-pl65 {
  padding-left: 65px !important;
}

.u-pb65 {
  padding-bottom: 65px !important;
}

.u-mt70 {
  margin-top: 70px !important;
}

.u-mr70 {
  margin-right: 70px !important;
}

.u-ml70 {
  margin-left: 70px !important;
}

.u-mb70 {
  margin-bottom: 70px !important;
}

.u-pt70 {
  padding-top: 70px !important;
}

.u-pr70 {
  padding-right: 70px !important;
}

.u-pl70 {
  padding-left: 70px !important;
}

.u-pb70 {
  padding-bottom: 70px !important;
}

.u-mt75 {
  margin-top: 75px !important;
}

.u-mr75 {
  margin-right: 75px !important;
}

.u-ml75 {
  margin-left: 75px !important;
}

.u-mb75 {
  margin-bottom: 75px !important;
}

.u-pt75 {
  padding-top: 75px !important;
}

.u-pr75 {
  padding-right: 75px !important;
}

.u-pl75 {
  padding-left: 75px !important;
}

.u-pb75 {
  padding-bottom: 75px !important;
}

.u-mt80 {
  margin-top: 80px !important;
}

.u-mr80 {
  margin-right: 80px !important;
}

.u-ml80 {
  margin-left: 80px !important;
}

.u-mb80 {
  margin-bottom: 80px !important;
}

.u-pt80 {
  padding-top: 80px !important;
}

.u-pr80 {
  padding-right: 80px !important;
}

.u-pl80 {
  padding-left: 80px !important;
}

.u-pb80 {
  padding-bottom: 80px !important;
}

.u-mt85 {
  margin-top: 85px !important;
}

.u-mr85 {
  margin-right: 85px !important;
}

.u-ml85 {
  margin-left: 85px !important;
}

.u-mb85 {
  margin-bottom: 85px !important;
}

.u-pt85 {
  padding-top: 85px !important;
}

.u-pr85 {
  padding-right: 85px !important;
}

.u-pl85 {
  padding-left: 85px !important;
}

.u-pb85 {
  padding-bottom: 85px !important;
}

.u-mt90 {
  margin-top: 90px !important;
}

.u-mr90 {
  margin-right: 90px !important;
}

.u-ml90 {
  margin-left: 90px !important;
}

.u-mb90 {
  margin-bottom: 90px !important;
}

.u-pt90 {
  padding-top: 90px !important;
}

.u-pr90 {
  padding-right: 90px !important;
}

.u-pl90 {
  padding-left: 90px !important;
}

.u-pb90 {
  padding-bottom: 90px !important;
}

.u-mt95 {
  margin-top: 95px !important;
}

.u-mr95 {
  margin-right: 95px !important;
}

.u-ml95 {
  margin-left: 95px !important;
}

.u-mb95 {
  margin-bottom: 95px !important;
}

.u-pt95 {
  padding-top: 95px !important;
}

.u-pr95 {
  padding-right: 95px !important;
}

.u-pl95 {
  padding-left: 95px !important;
}

.u-pb95 {
  padding-bottom: 95px !important;
}

.u-mt100 {
  margin-top: 100px !important;
}

.u-mr100 {
  margin-right: 100px !important;
}

.u-ml100 {
  margin-left: 100px !important;
}

.u-mb100 {
  margin-bottom: 100px !important;
}

.u-pt100 {
  padding-top: 100px !important;
}

.u-pr100 {
  padding-right: 100px !important;
}

.u-pl100 {
  padding-left: 100px !important;
}

.u-pb100 {
  padding-bottom: 100px !important;
}

@media screen and (max-width: 767px) {
  .u-mt0--sp {
    margin-top: 0px !important;
  }
  .u-mr0--sp {
    margin-right: 0px !important;
  }
  .u-ml0--sp {
    margin-left: 0px !important;
  }
  .u-mb0--sp {
    margin-bottom: 0px !important;
  }
  .u-pt0--sp {
    padding-top: 0px !important;
  }
  .u-pr0--sp {
    padding-right: 0px !important;
  }
  .u-pl0--sp {
    padding-left: 0px !important;
  }
  .u-pb0--sp {
    padding-bottom: 0px !important;
  }
  .u-mt5--sp {
    margin-top: 5px !important;
  }
  .u-mr5--sp {
    margin-right: 5px !important;
  }
  .u-ml5--sp {
    margin-left: 5px !important;
  }
  .u-mb5--sp {
    margin-bottom: 5px !important;
  }
  .u-pt5--sp {
    padding-top: 5px !important;
  }
  .u-pr5--sp {
    padding-right: 5px !important;
  }
  .u-pl5--sp {
    padding-left: 5px !important;
  }
  .u-pb5--sp {
    padding-bottom: 5px !important;
  }
  .u-mt10--sp {
    margin-top: 10px !important;
  }
  .u-mr10--sp {
    margin-right: 10px !important;
  }
  .u-ml10--sp {
    margin-left: 10px !important;
  }
  .u-mb10--sp {
    margin-bottom: 10px !important;
  }
  .u-pt10--sp {
    padding-top: 10px !important;
  }
  .u-pr10--sp {
    padding-right: 10px !important;
  }
  .u-pl10--sp {
    padding-left: 10px !important;
  }
  .u-pb10--sp {
    padding-bottom: 10px !important;
  }
  .u-mt15--sp {
    margin-top: 15px !important;
  }
  .u-mr15--sp {
    margin-right: 15px !important;
  }
  .u-ml15--sp {
    margin-left: 15px !important;
  }
  .u-mb15--sp {
    margin-bottom: 15px !important;
  }
  .u-pt15--sp {
    padding-top: 15px !important;
  }
  .u-pr15--sp {
    padding-right: 15px !important;
  }
  .u-pl15--sp {
    padding-left: 15px !important;
  }
  .u-pb15--sp {
    padding-bottom: 15px !important;
  }
  .u-mt20--sp {
    margin-top: 20px !important;
  }
  .u-mr20--sp {
    margin-right: 20px !important;
  }
  .u-ml20--sp {
    margin-left: 20px !important;
  }
  .u-mb20--sp {
    margin-bottom: 20px !important;
  }
  .u-pt20--sp {
    padding-top: 20px !important;
  }
  .u-pr20--sp {
    padding-right: 20px !important;
  }
  .u-pl20--sp {
    padding-left: 20px !important;
  }
  .u-pb20--sp {
    padding-bottom: 20px !important;
  }
  .u-mt25--sp {
    margin-top: 25px !important;
  }
  .u-mr25--sp {
    margin-right: 25px !important;
  }
  .u-ml25--sp {
    margin-left: 25px !important;
  }
  .u-mb25--sp {
    margin-bottom: 25px !important;
  }
  .u-pt25--sp {
    padding-top: 25px !important;
  }
  .u-pr25--sp {
    padding-right: 25px !important;
  }
  .u-pl25--sp {
    padding-left: 25px !important;
  }
  .u-pb25--sp {
    padding-bottom: 25px !important;
  }
  .u-mt30--sp {
    margin-top: 30px !important;
  }
  .u-mr30--sp {
    margin-right: 30px !important;
  }
  .u-ml30--sp {
    margin-left: 30px !important;
  }
  .u-mb30--sp {
    margin-bottom: 30px !important;
  }
  .u-pt30--sp {
    padding-top: 30px !important;
  }
  .u-pr30--sp {
    padding-right: 30px !important;
  }
  .u-pl30--sp {
    padding-left: 30px !important;
  }
  .u-pb30--sp {
    padding-bottom: 30px !important;
  }
  .u-mt35--sp {
    margin-top: 35px !important;
  }
  .u-mr35--sp {
    margin-right: 35px !important;
  }
  .u-ml35--sp {
    margin-left: 35px !important;
  }
  .u-mb35--sp {
    margin-bottom: 35px !important;
  }
  .u-pt35--sp {
    padding-top: 35px !important;
  }
  .u-pr35--sp {
    padding-right: 35px !important;
  }
  .u-pl35--sp {
    padding-left: 35px !important;
  }
  .u-pb35--sp {
    padding-bottom: 35px !important;
  }
  .u-mt40--sp {
    margin-top: 40px !important;
  }
  .u-mr40--sp {
    margin-right: 40px !important;
  }
  .u-ml40--sp {
    margin-left: 40px !important;
  }
  .u-mb40--sp {
    margin-bottom: 40px !important;
  }
  .u-pt40--sp {
    padding-top: 40px !important;
  }
  .u-pr40--sp {
    padding-right: 40px !important;
  }
  .u-pl40--sp {
    padding-left: 40px !important;
  }
  .u-pb40--sp {
    padding-bottom: 40px !important;
  }
  .u-mt45--sp {
    margin-top: 45px !important;
  }
  .u-mr45--sp {
    margin-right: 45px !important;
  }
  .u-ml45--sp {
    margin-left: 45px !important;
  }
  .u-mb45--sp {
    margin-bottom: 45px !important;
  }
  .u-pt45--sp {
    padding-top: 45px !important;
  }
  .u-pr45--sp {
    padding-right: 45px !important;
  }
  .u-pl45--sp {
    padding-left: 45px !important;
  }
  .u-pb45--sp {
    padding-bottom: 45px !important;
  }
  .u-mt50--sp {
    margin-top: 50px !important;
  }
  .u-mr50--sp {
    margin-right: 50px !important;
  }
  .u-ml50--sp {
    margin-left: 50px !important;
  }
  .u-mb50--sp {
    margin-bottom: 50px !important;
  }
  .u-pt50--sp {
    padding-top: 50px !important;
  }
  .u-pr50--sp {
    padding-right: 50px !important;
  }
  .u-pl50--sp {
    padding-left: 50px !important;
  }
  .u-pb50--sp {
    padding-bottom: 50px !important;
  }
  .u-mt55--sp {
    margin-top: 55px !important;
  }
  .u-mr55--sp {
    margin-right: 55px !important;
  }
  .u-ml55--sp {
    margin-left: 55px !important;
  }
  .u-mb55--sp {
    margin-bottom: 55px !important;
  }
  .u-pt55--sp {
    padding-top: 55px !important;
  }
  .u-pr55--sp {
    padding-right: 55px !important;
  }
  .u-pl55--sp {
    padding-left: 55px !important;
  }
  .u-pb55--sp {
    padding-bottom: 55px !important;
  }
  .u-mt60--sp {
    margin-top: 60px !important;
  }
  .u-mr60--sp {
    margin-right: 60px !important;
  }
  .u-ml60--sp {
    margin-left: 60px !important;
  }
  .u-mb60--sp {
    margin-bottom: 60px !important;
  }
  .u-pt60--sp {
    padding-top: 60px !important;
  }
  .u-pr60--sp {
    padding-right: 60px !important;
  }
  .u-pl60--sp {
    padding-left: 60px !important;
  }
  .u-pb60--sp {
    padding-bottom: 60px !important;
  }
  .u-mt65--sp {
    margin-top: 65px !important;
  }
  .u-mr65--sp {
    margin-right: 65px !important;
  }
  .u-ml65--sp {
    margin-left: 65px !important;
  }
  .u-mb65--sp {
    margin-bottom: 65px !important;
  }
  .u-pt65--sp {
    padding-top: 65px !important;
  }
  .u-pr65--sp {
    padding-right: 65px !important;
  }
  .u-pl65--sp {
    padding-left: 65px !important;
  }
  .u-pb65--sp {
    padding-bottom: 65px !important;
  }
  .u-mt70--sp {
    margin-top: 70px !important;
  }
  .u-mr70--sp {
    margin-right: 70px !important;
  }
  .u-ml70--sp {
    margin-left: 70px !important;
  }
  .u-mb70--sp {
    margin-bottom: 70px !important;
  }
  .u-pt70--sp {
    padding-top: 70px !important;
  }
  .u-pr70--sp {
    padding-right: 70px !important;
  }
  .u-pl70--sp {
    padding-left: 70px !important;
  }
  .u-pb70--sp {
    padding-bottom: 70px !important;
  }
  .u-mt75--sp {
    margin-top: 75px !important;
  }
  .u-mr75--sp {
    margin-right: 75px !important;
  }
  .u-ml75--sp {
    margin-left: 75px !important;
  }
  .u-mb75--sp {
    margin-bottom: 75px !important;
  }
  .u-pt75--sp {
    padding-top: 75px !important;
  }
  .u-pr75--sp {
    padding-right: 75px !important;
  }
  .u-pl75--sp {
    padding-left: 75px !important;
  }
  .u-pb75--sp {
    padding-bottom: 75px !important;
  }
  .u-mt80--sp {
    margin-top: 80px !important;
  }
  .u-mr80--sp {
    margin-right: 80px !important;
  }
  .u-ml80--sp {
    margin-left: 80px !important;
  }
  .u-mb80--sp {
    margin-bottom: 80px !important;
  }
  .u-pt80--sp {
    padding-top: 80px !important;
  }
  .u-pr80--sp {
    padding-right: 80px !important;
  }
  .u-pl80--sp {
    padding-left: 80px !important;
  }
  .u-pb80--sp {
    padding-bottom: 80px !important;
  }
  .u-mt85--sp {
    margin-top: 85px !important;
  }
  .u-mr85--sp {
    margin-right: 85px !important;
  }
  .u-ml85--sp {
    margin-left: 85px !important;
  }
  .u-mb85--sp {
    margin-bottom: 85px !important;
  }
  .u-pt85--sp {
    padding-top: 85px !important;
  }
  .u-pr85--sp {
    padding-right: 85px !important;
  }
  .u-pl85--sp {
    padding-left: 85px !important;
  }
  .u-pb85--sp {
    padding-bottom: 85px !important;
  }
  .u-mt90--sp {
    margin-top: 90px !important;
  }
  .u-mr90--sp {
    margin-right: 90px !important;
  }
  .u-ml90--sp {
    margin-left: 90px !important;
  }
  .u-mb90--sp {
    margin-bottom: 90px !important;
  }
  .u-pt90--sp {
    padding-top: 90px !important;
  }
  .u-pr90--sp {
    padding-right: 90px !important;
  }
  .u-pl90--sp {
    padding-left: 90px !important;
  }
  .u-pb90--sp {
    padding-bottom: 90px !important;
  }
  .u-mt95--sp {
    margin-top: 95px !important;
  }
  .u-mr95--sp {
    margin-right: 95px !important;
  }
  .u-ml95--sp {
    margin-left: 95px !important;
  }
  .u-mb95--sp {
    margin-bottom: 95px !important;
  }
  .u-pt95--sp {
    padding-top: 95px !important;
  }
  .u-pr95--sp {
    padding-right: 95px !important;
  }
  .u-pl95--sp {
    padding-left: 95px !important;
  }
  .u-pb95--sp {
    padding-bottom: 95px !important;
  }
  .u-mt100--sp {
    margin-top: 100px !important;
  }
  .u-mr100--sp {
    margin-right: 100px !important;
  }
  .u-ml100--sp {
    margin-left: 100px !important;
  }
  .u-mb100--sp {
    margin-bottom: 100px !important;
  }
  .u-pt100--sp {
    padding-top: 100px !important;
  }
  .u-pr100--sp {
    padding-right: 100px !important;
  }
  .u-pl100--sp {
    padding-left: 100px !important;
  }
  .u-pb100--sp {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-mt0--tab {
    margin-top: 0px !important;
  }
  .u-mr0--tab {
    margin-right: 0px !important;
  }
  .u-ml0--tab {
    margin-left: 0px !important;
  }
  .u-mb0--tab {
    margin-bottom: 0px !important;
  }
  .u-pt0--tab {
    padding-top: 0px !important;
  }
  .u-pr0--tab {
    padding-right: 0px !important;
  }
  .u-pl0--tab {
    padding-left: 0px !important;
  }
  .u-pb0--tab {
    padding-bottom: 0px !important;
  }
  .u-mt5--tab {
    margin-top: 5px !important;
  }
  .u-mr5--tab {
    margin-right: 5px !important;
  }
  .u-ml5--tab {
    margin-left: 5px !important;
  }
  .u-mb5--tab {
    margin-bottom: 5px !important;
  }
  .u-pt5--tab {
    padding-top: 5px !important;
  }
  .u-pr5--tab {
    padding-right: 5px !important;
  }
  .u-pl5--tab {
    padding-left: 5px !important;
  }
  .u-pb5--tab {
    padding-bottom: 5px !important;
  }
  .u-mt10--tab {
    margin-top: 10px !important;
  }
  .u-mr10--tab {
    margin-right: 10px !important;
  }
  .u-ml10--tab {
    margin-left: 10px !important;
  }
  .u-mb10--tab {
    margin-bottom: 10px !important;
  }
  .u-pt10--tab {
    padding-top: 10px !important;
  }
  .u-pr10--tab {
    padding-right: 10px !important;
  }
  .u-pl10--tab {
    padding-left: 10px !important;
  }
  .u-pb10--tab {
    padding-bottom: 10px !important;
  }
  .u-mt15--tab {
    margin-top: 15px !important;
  }
  .u-mr15--tab {
    margin-right: 15px !important;
  }
  .u-ml15--tab {
    margin-left: 15px !important;
  }
  .u-mb15--tab {
    margin-bottom: 15px !important;
  }
  .u-pt15--tab {
    padding-top: 15px !important;
  }
  .u-pr15--tab {
    padding-right: 15px !important;
  }
  .u-pl15--tab {
    padding-left: 15px !important;
  }
  .u-pb15--tab {
    padding-bottom: 15px !important;
  }
  .u-mt20--tab {
    margin-top: 20px !important;
  }
  .u-mr20--tab {
    margin-right: 20px !important;
  }
  .u-ml20--tab {
    margin-left: 20px !important;
  }
  .u-mb20--tab {
    margin-bottom: 20px !important;
  }
  .u-pt20--tab {
    padding-top: 20px !important;
  }
  .u-pr20--tab {
    padding-right: 20px !important;
  }
  .u-pl20--tab {
    padding-left: 20px !important;
  }
  .u-pb20--tab {
    padding-bottom: 20px !important;
  }
  .u-mt25--tab {
    margin-top: 25px !important;
  }
  .u-mr25--tab {
    margin-right: 25px !important;
  }
  .u-ml25--tab {
    margin-left: 25px !important;
  }
  .u-mb25--tab {
    margin-bottom: 25px !important;
  }
  .u-pt25--tab {
    padding-top: 25px !important;
  }
  .u-pr25--tab {
    padding-right: 25px !important;
  }
  .u-pl25--tab {
    padding-left: 25px !important;
  }
  .u-pb25--tab {
    padding-bottom: 25px !important;
  }
  .u-mt30--tab {
    margin-top: 30px !important;
  }
  .u-mr30--tab {
    margin-right: 30px !important;
  }
  .u-ml30--tab {
    margin-left: 30px !important;
  }
  .u-mb30--tab {
    margin-bottom: 30px !important;
  }
  .u-pt30--tab {
    padding-top: 30px !important;
  }
  .u-pr30--tab {
    padding-right: 30px !important;
  }
  .u-pl30--tab {
    padding-left: 30px !important;
  }
  .u-pb30--tab {
    padding-bottom: 30px !important;
  }
  .u-mt35--tab {
    margin-top: 35px !important;
  }
  .u-mr35--tab {
    margin-right: 35px !important;
  }
  .u-ml35--tab {
    margin-left: 35px !important;
  }
  .u-mb35--tab {
    margin-bottom: 35px !important;
  }
  .u-pt35--tab {
    padding-top: 35px !important;
  }
  .u-pr35--tab {
    padding-right: 35px !important;
  }
  .u-pl35--tab {
    padding-left: 35px !important;
  }
  .u-pb35--tab {
    padding-bottom: 35px !important;
  }
  .u-mt40--tab {
    margin-top: 40px !important;
  }
  .u-mr40--tab {
    margin-right: 40px !important;
  }
  .u-ml40--tab {
    margin-left: 40px !important;
  }
  .u-mb40--tab {
    margin-bottom: 40px !important;
  }
  .u-pt40--tab {
    padding-top: 40px !important;
  }
  .u-pr40--tab {
    padding-right: 40px !important;
  }
  .u-pl40--tab {
    padding-left: 40px !important;
  }
  .u-pb40--tab {
    padding-bottom: 40px !important;
  }
  .u-mt45--tab {
    margin-top: 45px !important;
  }
  .u-mr45--tab {
    margin-right: 45px !important;
  }
  .u-ml45--tab {
    margin-left: 45px !important;
  }
  .u-mb45--tab {
    margin-bottom: 45px !important;
  }
  .u-pt45--tab {
    padding-top: 45px !important;
  }
  .u-pr45--tab {
    padding-right: 45px !important;
  }
  .u-pl45--tab {
    padding-left: 45px !important;
  }
  .u-pb45--tab {
    padding-bottom: 45px !important;
  }
  .u-mt50--tab {
    margin-top: 50px !important;
  }
  .u-mr50--tab {
    margin-right: 50px !important;
  }
  .u-ml50--tab {
    margin-left: 50px !important;
  }
  .u-mb50--tab {
    margin-bottom: 50px !important;
  }
  .u-pt50--tab {
    padding-top: 50px !important;
  }
  .u-pr50--tab {
    padding-right: 50px !important;
  }
  .u-pl50--tab {
    padding-left: 50px !important;
  }
  .u-pb50--tab {
    padding-bottom: 50px !important;
  }
  .u-mt55--tab {
    margin-top: 55px !important;
  }
  .u-mr55--tab {
    margin-right: 55px !important;
  }
  .u-ml55--tab {
    margin-left: 55px !important;
  }
  .u-mb55--tab {
    margin-bottom: 55px !important;
  }
  .u-pt55--tab {
    padding-top: 55px !important;
  }
  .u-pr55--tab {
    padding-right: 55px !important;
  }
  .u-pl55--tab {
    padding-left: 55px !important;
  }
  .u-pb55--tab {
    padding-bottom: 55px !important;
  }
  .u-mt60--tab {
    margin-top: 60px !important;
  }
  .u-mr60--tab {
    margin-right: 60px !important;
  }
  .u-ml60--tab {
    margin-left: 60px !important;
  }
  .u-mb60--tab {
    margin-bottom: 60px !important;
  }
  .u-pt60--tab {
    padding-top: 60px !important;
  }
  .u-pr60--tab {
    padding-right: 60px !important;
  }
  .u-pl60--tab {
    padding-left: 60px !important;
  }
  .u-pb60--tab {
    padding-bottom: 60px !important;
  }
  .u-mt65--tab {
    margin-top: 65px !important;
  }
  .u-mr65--tab {
    margin-right: 65px !important;
  }
  .u-ml65--tab {
    margin-left: 65px !important;
  }
  .u-mb65--tab {
    margin-bottom: 65px !important;
  }
  .u-pt65--tab {
    padding-top: 65px !important;
  }
  .u-pr65--tab {
    padding-right: 65px !important;
  }
  .u-pl65--tab {
    padding-left: 65px !important;
  }
  .u-pb65--tab {
    padding-bottom: 65px !important;
  }
  .u-mt70--tab {
    margin-top: 70px !important;
  }
  .u-mr70--tab {
    margin-right: 70px !important;
  }
  .u-ml70--tab {
    margin-left: 70px !important;
  }
  .u-mb70--tab {
    margin-bottom: 70px !important;
  }
  .u-pt70--tab {
    padding-top: 70px !important;
  }
  .u-pr70--tab {
    padding-right: 70px !important;
  }
  .u-pl70--tab {
    padding-left: 70px !important;
  }
  .u-pb70--tab {
    padding-bottom: 70px !important;
  }
  .u-mt75--tab {
    margin-top: 75px !important;
  }
  .u-mr75--tab {
    margin-right: 75px !important;
  }
  .u-ml75--tab {
    margin-left: 75px !important;
  }
  .u-mb75--tab {
    margin-bottom: 75px !important;
  }
  .u-pt75--tab {
    padding-top: 75px !important;
  }
  .u-pr75--tab {
    padding-right: 75px !important;
  }
  .u-pl75--tab {
    padding-left: 75px !important;
  }
  .u-pb75--tab {
    padding-bottom: 75px !important;
  }
  .u-mt80--tab {
    margin-top: 80px !important;
  }
  .u-mr80--tab {
    margin-right: 80px !important;
  }
  .u-ml80--tab {
    margin-left: 80px !important;
  }
  .u-mb80--tab {
    margin-bottom: 80px !important;
  }
  .u-pt80--tab {
    padding-top: 80px !important;
  }
  .u-pr80--tab {
    padding-right: 80px !important;
  }
  .u-pl80--tab {
    padding-left: 80px !important;
  }
  .u-pb80--tab {
    padding-bottom: 80px !important;
  }
  .u-mt85--tab {
    margin-top: 85px !important;
  }
  .u-mr85--tab {
    margin-right: 85px !important;
  }
  .u-ml85--tab {
    margin-left: 85px !important;
  }
  .u-mb85--tab {
    margin-bottom: 85px !important;
  }
  .u-pt85--tab {
    padding-top: 85px !important;
  }
  .u-pr85--tab {
    padding-right: 85px !important;
  }
  .u-pl85--tab {
    padding-left: 85px !important;
  }
  .u-pb85--tab {
    padding-bottom: 85px !important;
  }
  .u-mt90--tab {
    margin-top: 90px !important;
  }
  .u-mr90--tab {
    margin-right: 90px !important;
  }
  .u-ml90--tab {
    margin-left: 90px !important;
  }
  .u-mb90--tab {
    margin-bottom: 90px !important;
  }
  .u-pt90--tab {
    padding-top: 90px !important;
  }
  .u-pr90--tab {
    padding-right: 90px !important;
  }
  .u-pl90--tab {
    padding-left: 90px !important;
  }
  .u-pb90--tab {
    padding-bottom: 90px !important;
  }
  .u-mt95--tab {
    margin-top: 95px !important;
  }
  .u-mr95--tab {
    margin-right: 95px !important;
  }
  .u-ml95--tab {
    margin-left: 95px !important;
  }
  .u-mb95--tab {
    margin-bottom: 95px !important;
  }
  .u-pt95--tab {
    padding-top: 95px !important;
  }
  .u-pr95--tab {
    padding-right: 95px !important;
  }
  .u-pl95--tab {
    padding-left: 95px !important;
  }
  .u-pb95--tab {
    padding-bottom: 95px !important;
  }
  .u-mt100--tab {
    margin-top: 100px !important;
  }
  .u-mr100--tab {
    margin-right: 100px !important;
  }
  .u-ml100--tab {
    margin-left: 100px !important;
  }
  .u-mb100--tab {
    margin-bottom: 100px !important;
  }
  .u-pt100--tab {
    padding-top: 100px !important;
  }
  .u-pr100--tab {
    padding-right: 100px !important;
  }
  .u-pl100--tab {
    padding-left: 100px !important;
  }
  .u-pb100--tab {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 1280px) {
  .u-mt0--laptop {
    margin-top: 0px !important;
  }
  .u-mr0--laptop {
    margin-right: 0px !important;
  }
  .u-ml0--laptop {
    margin-left: 0px !important;
  }
  .u-mb0--laptop {
    margin-bottom: 0px !important;
  }
  .u-pt0--laptop {
    padding-top: 0px !important;
  }
  .u-pr0--laptop {
    padding-right: 0px !important;
  }
  .u-pl0--laptop {
    padding-left: 0px !important;
  }
  .u-pb0--laptop {
    padding-bottom: 0px !important;
  }
  .u-mt5--laptop {
    margin-top: 5px !important;
  }
  .u-mr5--laptop {
    margin-right: 5px !important;
  }
  .u-ml5--laptop {
    margin-left: 5px !important;
  }
  .u-mb5--laptop {
    margin-bottom: 5px !important;
  }
  .u-pt5--laptop {
    padding-top: 5px !important;
  }
  .u-pr5--laptop {
    padding-right: 5px !important;
  }
  .u-pl5--laptop {
    padding-left: 5px !important;
  }
  .u-pb5--laptop {
    padding-bottom: 5px !important;
  }
  .u-mt10--laptop {
    margin-top: 10px !important;
  }
  .u-mr10--laptop {
    margin-right: 10px !important;
  }
  .u-ml10--laptop {
    margin-left: 10px !important;
  }
  .u-mb10--laptop {
    margin-bottom: 10px !important;
  }
  .u-pt10--laptop {
    padding-top: 10px !important;
  }
  .u-pr10--laptop {
    padding-right: 10px !important;
  }
  .u-pl10--laptop {
    padding-left: 10px !important;
  }
  .u-pb10--laptop {
    padding-bottom: 10px !important;
  }
  .u-mt15--laptop {
    margin-top: 15px !important;
  }
  .u-mr15--laptop {
    margin-right: 15px !important;
  }
  .u-ml15--laptop {
    margin-left: 15px !important;
  }
  .u-mb15--laptop {
    margin-bottom: 15px !important;
  }
  .u-pt15--laptop {
    padding-top: 15px !important;
  }
  .u-pr15--laptop {
    padding-right: 15px !important;
  }
  .u-pl15--laptop {
    padding-left: 15px !important;
  }
  .u-pb15--laptop {
    padding-bottom: 15px !important;
  }
  .u-mt20--laptop {
    margin-top: 20px !important;
  }
  .u-mr20--laptop {
    margin-right: 20px !important;
  }
  .u-ml20--laptop {
    margin-left: 20px !important;
  }
  .u-mb20--laptop {
    margin-bottom: 20px !important;
  }
  .u-pt20--laptop {
    padding-top: 20px !important;
  }
  .u-pr20--laptop {
    padding-right: 20px !important;
  }
  .u-pl20--laptop {
    padding-left: 20px !important;
  }
  .u-pb20--laptop {
    padding-bottom: 20px !important;
  }
  .u-mt25--laptop {
    margin-top: 25px !important;
  }
  .u-mr25--laptop {
    margin-right: 25px !important;
  }
  .u-ml25--laptop {
    margin-left: 25px !important;
  }
  .u-mb25--laptop {
    margin-bottom: 25px !important;
  }
  .u-pt25--laptop {
    padding-top: 25px !important;
  }
  .u-pr25--laptop {
    padding-right: 25px !important;
  }
  .u-pl25--laptop {
    padding-left: 25px !important;
  }
  .u-pb25--laptop {
    padding-bottom: 25px !important;
  }
  .u-mt30--laptop {
    margin-top: 30px !important;
  }
  .u-mr30--laptop {
    margin-right: 30px !important;
  }
  .u-ml30--laptop {
    margin-left: 30px !important;
  }
  .u-mb30--laptop {
    margin-bottom: 30px !important;
  }
  .u-pt30--laptop {
    padding-top: 30px !important;
  }
  .u-pr30--laptop {
    padding-right: 30px !important;
  }
  .u-pl30--laptop {
    padding-left: 30px !important;
  }
  .u-pb30--laptop {
    padding-bottom: 30px !important;
  }
  .u-mt35--laptop {
    margin-top: 35px !important;
  }
  .u-mr35--laptop {
    margin-right: 35px !important;
  }
  .u-ml35--laptop {
    margin-left: 35px !important;
  }
  .u-mb35--laptop {
    margin-bottom: 35px !important;
  }
  .u-pt35--laptop {
    padding-top: 35px !important;
  }
  .u-pr35--laptop {
    padding-right: 35px !important;
  }
  .u-pl35--laptop {
    padding-left: 35px !important;
  }
  .u-pb35--laptop {
    padding-bottom: 35px !important;
  }
  .u-mt40--laptop {
    margin-top: 40px !important;
  }
  .u-mr40--laptop {
    margin-right: 40px !important;
  }
  .u-ml40--laptop {
    margin-left: 40px !important;
  }
  .u-mb40--laptop {
    margin-bottom: 40px !important;
  }
  .u-pt40--laptop {
    padding-top: 40px !important;
  }
  .u-pr40--laptop {
    padding-right: 40px !important;
  }
  .u-pl40--laptop {
    padding-left: 40px !important;
  }
  .u-pb40--laptop {
    padding-bottom: 40px !important;
  }
  .u-mt45--laptop {
    margin-top: 45px !important;
  }
  .u-mr45--laptop {
    margin-right: 45px !important;
  }
  .u-ml45--laptop {
    margin-left: 45px !important;
  }
  .u-mb45--laptop {
    margin-bottom: 45px !important;
  }
  .u-pt45--laptop {
    padding-top: 45px !important;
  }
  .u-pr45--laptop {
    padding-right: 45px !important;
  }
  .u-pl45--laptop {
    padding-left: 45px !important;
  }
  .u-pb45--laptop {
    padding-bottom: 45px !important;
  }
  .u-mt50--laptop {
    margin-top: 50px !important;
  }
  .u-mr50--laptop {
    margin-right: 50px !important;
  }
  .u-ml50--laptop {
    margin-left: 50px !important;
  }
  .u-mb50--laptop {
    margin-bottom: 50px !important;
  }
  .u-pt50--laptop {
    padding-top: 50px !important;
  }
  .u-pr50--laptop {
    padding-right: 50px !important;
  }
  .u-pl50--laptop {
    padding-left: 50px !important;
  }
  .u-pb50--laptop {
    padding-bottom: 50px !important;
  }
  .u-mt55--laptop {
    margin-top: 55px !important;
  }
  .u-mr55--laptop {
    margin-right: 55px !important;
  }
  .u-ml55--laptop {
    margin-left: 55px !important;
  }
  .u-mb55--laptop {
    margin-bottom: 55px !important;
  }
  .u-pt55--laptop {
    padding-top: 55px !important;
  }
  .u-pr55--laptop {
    padding-right: 55px !important;
  }
  .u-pl55--laptop {
    padding-left: 55px !important;
  }
  .u-pb55--laptop {
    padding-bottom: 55px !important;
  }
  .u-mt60--laptop {
    margin-top: 60px !important;
  }
  .u-mr60--laptop {
    margin-right: 60px !important;
  }
  .u-ml60--laptop {
    margin-left: 60px !important;
  }
  .u-mb60--laptop {
    margin-bottom: 60px !important;
  }
  .u-pt60--laptop {
    padding-top: 60px !important;
  }
  .u-pr60--laptop {
    padding-right: 60px !important;
  }
  .u-pl60--laptop {
    padding-left: 60px !important;
  }
  .u-pb60--laptop {
    padding-bottom: 60px !important;
  }
  .u-mt65--laptop {
    margin-top: 65px !important;
  }
  .u-mr65--laptop {
    margin-right: 65px !important;
  }
  .u-ml65--laptop {
    margin-left: 65px !important;
  }
  .u-mb65--laptop {
    margin-bottom: 65px !important;
  }
  .u-pt65--laptop {
    padding-top: 65px !important;
  }
  .u-pr65--laptop {
    padding-right: 65px !important;
  }
  .u-pl65--laptop {
    padding-left: 65px !important;
  }
  .u-pb65--laptop {
    padding-bottom: 65px !important;
  }
  .u-mt70--laptop {
    margin-top: 70px !important;
  }
  .u-mr70--laptop {
    margin-right: 70px !important;
  }
  .u-ml70--laptop {
    margin-left: 70px !important;
  }
  .u-mb70--laptop {
    margin-bottom: 70px !important;
  }
  .u-pt70--laptop {
    padding-top: 70px !important;
  }
  .u-pr70--laptop {
    padding-right: 70px !important;
  }
  .u-pl70--laptop {
    padding-left: 70px !important;
  }
  .u-pb70--laptop {
    padding-bottom: 70px !important;
  }
  .u-mt75--laptop {
    margin-top: 75px !important;
  }
  .u-mr75--laptop {
    margin-right: 75px !important;
  }
  .u-ml75--laptop {
    margin-left: 75px !important;
  }
  .u-mb75--laptop {
    margin-bottom: 75px !important;
  }
  .u-pt75--laptop {
    padding-top: 75px !important;
  }
  .u-pr75--laptop {
    padding-right: 75px !important;
  }
  .u-pl75--laptop {
    padding-left: 75px !important;
  }
  .u-pb75--laptop {
    padding-bottom: 75px !important;
  }
  .u-mt80--laptop {
    margin-top: 80px !important;
  }
  .u-mr80--laptop {
    margin-right: 80px !important;
  }
  .u-ml80--laptop {
    margin-left: 80px !important;
  }
  .u-mb80--laptop {
    margin-bottom: 80px !important;
  }
  .u-pt80--laptop {
    padding-top: 80px !important;
  }
  .u-pr80--laptop {
    padding-right: 80px !important;
  }
  .u-pl80--laptop {
    padding-left: 80px !important;
  }
  .u-pb80--laptop {
    padding-bottom: 80px !important;
  }
  .u-mt85--laptop {
    margin-top: 85px !important;
  }
  .u-mr85--laptop {
    margin-right: 85px !important;
  }
  .u-ml85--laptop {
    margin-left: 85px !important;
  }
  .u-mb85--laptop {
    margin-bottom: 85px !important;
  }
  .u-pt85--laptop {
    padding-top: 85px !important;
  }
  .u-pr85--laptop {
    padding-right: 85px !important;
  }
  .u-pl85--laptop {
    padding-left: 85px !important;
  }
  .u-pb85--laptop {
    padding-bottom: 85px !important;
  }
  .u-mt90--laptop {
    margin-top: 90px !important;
  }
  .u-mr90--laptop {
    margin-right: 90px !important;
  }
  .u-ml90--laptop {
    margin-left: 90px !important;
  }
  .u-mb90--laptop {
    margin-bottom: 90px !important;
  }
  .u-pt90--laptop {
    padding-top: 90px !important;
  }
  .u-pr90--laptop {
    padding-right: 90px !important;
  }
  .u-pl90--laptop {
    padding-left: 90px !important;
  }
  .u-pb90--laptop {
    padding-bottom: 90px !important;
  }
  .u-mt95--laptop {
    margin-top: 95px !important;
  }
  .u-mr95--laptop {
    margin-right: 95px !important;
  }
  .u-ml95--laptop {
    margin-left: 95px !important;
  }
  .u-mb95--laptop {
    margin-bottom: 95px !important;
  }
  .u-pt95--laptop {
    padding-top: 95px !important;
  }
  .u-pr95--laptop {
    padding-right: 95px !important;
  }
  .u-pl95--laptop {
    padding-left: 95px !important;
  }
  .u-pb95--laptop {
    padding-bottom: 95px !important;
  }
  .u-mt100--laptop {
    margin-top: 100px !important;
  }
  .u-mr100--laptop {
    margin-right: 100px !important;
  }
  .u-ml100--laptop {
    margin-left: 100px !important;
  }
  .u-mb100--laptop {
    margin-bottom: 100px !important;
  }
  .u-pt100--laptop {
    padding-top: 100px !important;
  }
  .u-pr100--laptop {
    padding-right: 100px !important;
  }
  .u-pl100--laptop {
    padding-left: 100px !important;
  }
  .u-pb100--laptop {
    padding-bottom: 100px !important;
  }
}

@media screen and (min-width: 768px) {
  .u-mt0--pc {
    margin-top: 0px !important;
  }
  .u-mr0--pc {
    margin-right: 0px !important;
  }
  .u-ml0--pc {
    margin-left: 0px !important;
  }
  .u-mb0--pc {
    margin-bottom: 0px !important;
  }
  .u-pt0--pc {
    padding-top: 0px !important;
  }
  .u-pr0--pc {
    padding-right: 0px !important;
  }
  .u-pl0--pc {
    padding-left: 0px !important;
  }
  .u-pb0--pc {
    padding-bottom: 0px !important;
  }
  .u-mt5--pc {
    margin-top: 5px !important;
  }
  .u-mr5--pc {
    margin-right: 5px !important;
  }
  .u-ml5--pc {
    margin-left: 5px !important;
  }
  .u-mb5--pc {
    margin-bottom: 5px !important;
  }
  .u-pt5--pc {
    padding-top: 5px !important;
  }
  .u-pr5--pc {
    padding-right: 5px !important;
  }
  .u-pl5--pc {
    padding-left: 5px !important;
  }
  .u-pb5--pc {
    padding-bottom: 5px !important;
  }
  .u-mt10--pc {
    margin-top: 10px !important;
  }
  .u-mr10--pc {
    margin-right: 10px !important;
  }
  .u-ml10--pc {
    margin-left: 10px !important;
  }
  .u-mb10--pc {
    margin-bottom: 10px !important;
  }
  .u-pt10--pc {
    padding-top: 10px !important;
  }
  .u-pr10--pc {
    padding-right: 10px !important;
  }
  .u-pl10--pc {
    padding-left: 10px !important;
  }
  .u-pb10--pc {
    padding-bottom: 10px !important;
  }
  .u-mt15--pc {
    margin-top: 15px !important;
  }
  .u-mr15--pc {
    margin-right: 15px !important;
  }
  .u-ml15--pc {
    margin-left: 15px !important;
  }
  .u-mb15--pc {
    margin-bottom: 15px !important;
  }
  .u-pt15--pc {
    padding-top: 15px !important;
  }
  .u-pr15--pc {
    padding-right: 15px !important;
  }
  .u-pl15--pc {
    padding-left: 15px !important;
  }
  .u-pb15--pc {
    padding-bottom: 15px !important;
  }
  .u-mt20--pc {
    margin-top: 20px !important;
  }
  .u-mr20--pc {
    margin-right: 20px !important;
  }
  .u-ml20--pc {
    margin-left: 20px !important;
  }
  .u-mb20--pc {
    margin-bottom: 20px !important;
  }
  .u-pt20--pc {
    padding-top: 20px !important;
  }
  .u-pr20--pc {
    padding-right: 20px !important;
  }
  .u-pl20--pc {
    padding-left: 20px !important;
  }
  .u-pb20--pc {
    padding-bottom: 20px !important;
  }
  .u-mt25--pc {
    margin-top: 25px !important;
  }
  .u-mr25--pc {
    margin-right: 25px !important;
  }
  .u-ml25--pc {
    margin-left: 25px !important;
  }
  .u-mb25--pc {
    margin-bottom: 25px !important;
  }
  .u-pt25--pc {
    padding-top: 25px !important;
  }
  .u-pr25--pc {
    padding-right: 25px !important;
  }
  .u-pl25--pc {
    padding-left: 25px !important;
  }
  .u-pb25--pc {
    padding-bottom: 25px !important;
  }
  .u-mt30--pc {
    margin-top: 30px !important;
  }
  .u-mr30--pc {
    margin-right: 30px !important;
  }
  .u-ml30--pc {
    margin-left: 30px !important;
  }
  .u-mb30--pc {
    margin-bottom: 30px !important;
  }
  .u-pt30--pc {
    padding-top: 30px !important;
  }
  .u-pr30--pc {
    padding-right: 30px !important;
  }
  .u-pl30--pc {
    padding-left: 30px !important;
  }
  .u-pb30--pc {
    padding-bottom: 30px !important;
  }
  .u-mt35--pc {
    margin-top: 35px !important;
  }
  .u-mr35--pc {
    margin-right: 35px !important;
  }
  .u-ml35--pc {
    margin-left: 35px !important;
  }
  .u-mb35--pc {
    margin-bottom: 35px !important;
  }
  .u-pt35--pc {
    padding-top: 35px !important;
  }
  .u-pr35--pc {
    padding-right: 35px !important;
  }
  .u-pl35--pc {
    padding-left: 35px !important;
  }
  .u-pb35--pc {
    padding-bottom: 35px !important;
  }
  .u-mt40--pc {
    margin-top: 40px !important;
  }
  .u-mr40--pc {
    margin-right: 40px !important;
  }
  .u-ml40--pc {
    margin-left: 40px !important;
  }
  .u-mb40--pc {
    margin-bottom: 40px !important;
  }
  .u-pt40--pc {
    padding-top: 40px !important;
  }
  .u-pr40--pc {
    padding-right: 40px !important;
  }
  .u-pl40--pc {
    padding-left: 40px !important;
  }
  .u-pb40--pc {
    padding-bottom: 40px !important;
  }
  .u-mt45--pc {
    margin-top: 45px !important;
  }
  .u-mr45--pc {
    margin-right: 45px !important;
  }
  .u-ml45--pc {
    margin-left: 45px !important;
  }
  .u-mb45--pc {
    margin-bottom: 45px !important;
  }
  .u-pt45--pc {
    padding-top: 45px !important;
  }
  .u-pr45--pc {
    padding-right: 45px !important;
  }
  .u-pl45--pc {
    padding-left: 45px !important;
  }
  .u-pb45--pc {
    padding-bottom: 45px !important;
  }
  .u-mt50--pc {
    margin-top: 50px !important;
  }
  .u-mr50--pc {
    margin-right: 50px !important;
  }
  .u-ml50--pc {
    margin-left: 50px !important;
  }
  .u-mb50--pc {
    margin-bottom: 50px !important;
  }
  .u-pt50--pc {
    padding-top: 50px !important;
  }
  .u-pr50--pc {
    padding-right: 50px !important;
  }
  .u-pl50--pc {
    padding-left: 50px !important;
  }
  .u-pb50--pc {
    padding-bottom: 50px !important;
  }
  .u-mt55--pc {
    margin-top: 55px !important;
  }
  .u-mr55--pc {
    margin-right: 55px !important;
  }
  .u-ml55--pc {
    margin-left: 55px !important;
  }
  .u-mb55--pc {
    margin-bottom: 55px !important;
  }
  .u-pt55--pc {
    padding-top: 55px !important;
  }
  .u-pr55--pc {
    padding-right: 55px !important;
  }
  .u-pl55--pc {
    padding-left: 55px !important;
  }
  .u-pb55--pc {
    padding-bottom: 55px !important;
  }
  .u-mt60--pc {
    margin-top: 60px !important;
  }
  .u-mr60--pc {
    margin-right: 60px !important;
  }
  .u-ml60--pc {
    margin-left: 60px !important;
  }
  .u-mb60--pc {
    margin-bottom: 60px !important;
  }
  .u-pt60--pc {
    padding-top: 60px !important;
  }
  .u-pr60--pc {
    padding-right: 60px !important;
  }
  .u-pl60--pc {
    padding-left: 60px !important;
  }
  .u-pb60--pc {
    padding-bottom: 60px !important;
  }
  .u-mt65--pc {
    margin-top: 65px !important;
  }
  .u-mr65--pc {
    margin-right: 65px !important;
  }
  .u-ml65--pc {
    margin-left: 65px !important;
  }
  .u-mb65--pc {
    margin-bottom: 65px !important;
  }
  .u-pt65--pc {
    padding-top: 65px !important;
  }
  .u-pr65--pc {
    padding-right: 65px !important;
  }
  .u-pl65--pc {
    padding-left: 65px !important;
  }
  .u-pb65--pc {
    padding-bottom: 65px !important;
  }
  .u-mt70--pc {
    margin-top: 70px !important;
  }
  .u-mr70--pc {
    margin-right: 70px !important;
  }
  .u-ml70--pc {
    margin-left: 70px !important;
  }
  .u-mb70--pc {
    margin-bottom: 70px !important;
  }
  .u-pt70--pc {
    padding-top: 70px !important;
  }
  .u-pr70--pc {
    padding-right: 70px !important;
  }
  .u-pl70--pc {
    padding-left: 70px !important;
  }
  .u-pb70--pc {
    padding-bottom: 70px !important;
  }
  .u-mt75--pc {
    margin-top: 75px !important;
  }
  .u-mr75--pc {
    margin-right: 75px !important;
  }
  .u-ml75--pc {
    margin-left: 75px !important;
  }
  .u-mb75--pc {
    margin-bottom: 75px !important;
  }
  .u-pt75--pc {
    padding-top: 75px !important;
  }
  .u-pr75--pc {
    padding-right: 75px !important;
  }
  .u-pl75--pc {
    padding-left: 75px !important;
  }
  .u-pb75--pc {
    padding-bottom: 75px !important;
  }
  .u-mt80--pc {
    margin-top: 80px !important;
  }
  .u-mr80--pc {
    margin-right: 80px !important;
  }
  .u-ml80--pc {
    margin-left: 80px !important;
  }
  .u-mb80--pc {
    margin-bottom: 80px !important;
  }
  .u-pt80--pc {
    padding-top: 80px !important;
  }
  .u-pr80--pc {
    padding-right: 80px !important;
  }
  .u-pl80--pc {
    padding-left: 80px !important;
  }
  .u-pb80--pc {
    padding-bottom: 80px !important;
  }
  .u-mt85--pc {
    margin-top: 85px !important;
  }
  .u-mr85--pc {
    margin-right: 85px !important;
  }
  .u-ml85--pc {
    margin-left: 85px !important;
  }
  .u-mb85--pc {
    margin-bottom: 85px !important;
  }
  .u-pt85--pc {
    padding-top: 85px !important;
  }
  .u-pr85--pc {
    padding-right: 85px !important;
  }
  .u-pl85--pc {
    padding-left: 85px !important;
  }
  .u-pb85--pc {
    padding-bottom: 85px !important;
  }
  .u-mt90--pc {
    margin-top: 90px !important;
  }
  .u-mr90--pc {
    margin-right: 90px !important;
  }
  .u-ml90--pc {
    margin-left: 90px !important;
  }
  .u-mb90--pc {
    margin-bottom: 90px !important;
  }
  .u-pt90--pc {
    padding-top: 90px !important;
  }
  .u-pr90--pc {
    padding-right: 90px !important;
  }
  .u-pl90--pc {
    padding-left: 90px !important;
  }
  .u-pb90--pc {
    padding-bottom: 90px !important;
  }
  .u-mt95--pc {
    margin-top: 95px !important;
  }
  .u-mr95--pc {
    margin-right: 95px !important;
  }
  .u-ml95--pc {
    margin-left: 95px !important;
  }
  .u-mb95--pc {
    margin-bottom: 95px !important;
  }
  .u-pt95--pc {
    padding-top: 95px !important;
  }
  .u-pr95--pc {
    padding-right: 95px !important;
  }
  .u-pl95--pc {
    padding-left: 95px !important;
  }
  .u-pb95--pc {
    padding-bottom: 95px !important;
  }
  .u-mt100--pc {
    margin-top: 100px !important;
  }
  .u-mr100--pc {
    margin-right: 100px !important;
  }
  .u-ml100--pc {
    margin-left: 100px !important;
  }
  .u-mb100--pc {
    margin-bottom: 100px !important;
  }
  .u-pt100--pc {
    padding-top: 100px !important;
  }
  .u-pr100--pc {
    padding-right: 100px !important;
  }
  .u-pl100--pc {
    padding-left: 100px !important;
  }
  .u-pb100--pc {
    padding-bottom: 100px !important;
  }
}

/* --------------------------
テキスト装飾
-------------------------- */
.u-text-accent {
  color: #0b2b75;
}

.u-text-important {
  color: #0c8100;
}

.u-text-inverse {
  color: #fff;
}

.u-text-sub {
  color: #ababab;
}

.u-text-en {
  font-family: 'EB Garamond', serif;
}

.u-text-bold {
  font-weight: 400;
}

.u-text-normal {
  font-weight: 300;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-text-lineheight2 {
  line-height: 2em;
}

.u-text-lineheight3 {
  line-height: 3em !important;
}

.u-text-x-large {
  font-size: 26px;
  line-height: 1.8em;
}

@media screen and (max-width: 1023px) {
  .u-text-x-large {
    font-size: 20px;
  }
}

.u-text-large {
  font-size: 22px;
  line-height: 1.8em;
}

@media screen and (max-width: 767px) {
  .u-text-large {
    font-size: 20px;
  }
}

.u-text-bit-large {
  font-size: 18px;
  line-height: 1.8em;
}

@media screen and (max-width: 767px) {
  .u-text-bit-large {
    font-size: 18px;
  }
}

.u-text-default {
  font-size: 16px;
  line-height: 1.8em;
}

@media screen and (max-width: 767px) {
  .u-text-default {
    font-size: 14px;
  }
}

.u-text-small {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .u-text-small {
    font-size: 14px;
  }
}

.u-text-x-small {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .u-text-x-small {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .u-text-x-small--sp {
    font-size: 12px;
  }
}

.u-text-cap {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  font-weight: bold;
  filter: drop-shadow(0 0 2px black);
}

.u-text-cap-wrap {
  position: relative;
}

/* --------------------------
幅指定
-------------------------- */
.u-w0 {
  width: 0%;
  display: block;
}

.u-w10 {
  width: 10%;
  display: block;
}

.u-w20 {
  width: 20%;
  display: block;
}

.u-w30 {
  width: 30%;
  display: block;
}

.u-w40 {
  width: 40%;
  display: block;
}

.u-w50 {
  width: 50%;
  display: block;
}

.u-w60 {
  width: 60%;
  display: block;
}

.u-w70 {
  width: 70%;
  display: block;
}

.u-w80 {
  width: 80%;
  display: block;
}

.u-w90 {
  width: 90%;
  display: block;
}

.u-w100 {
  width: 100%;
  display: block;
}

@media screen and (min-width: 768px) {
  .u-w0--pc {
    width: 0%;
    display: block;
  }
  .u-w10--pc {
    width: 10%;
    display: block;
  }
  .u-w20--pc {
    width: 20%;
    display: block;
  }
  .u-w30--pc {
    width: 30%;
    display: block;
  }
  .u-w40--pc {
    width: 40%;
    display: block;
  }
  .u-w50--pc {
    width: 50%;
    display: block;
  }
  .u-w60--pc {
    width: 60%;
    display: block;
  }
  .u-w70--pc {
    width: 70%;
    display: block;
  }
  .u-w80--pc {
    width: 80%;
    display: block;
  }
  .u-w90--pc {
    width: 90%;
    display: block;
  }
  .u-w100--pc {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 1280px) {
  .u-w0--laptop {
    width: 0%;
    display: block;
  }
  .u-w10--laptop {
    width: 10%;
    display: block;
  }
  .u-w20--laptop {
    width: 20%;
    display: block;
  }
  .u-w30--laptop {
    width: 30%;
    display: block;
  }
  .u-w40--laptop {
    width: 40%;
    display: block;
  }
  .u-w50--laptop {
    width: 50%;
    display: block;
  }
  .u-w60--laptop {
    width: 60%;
    display: block;
  }
  .u-w70--laptop {
    width: 70%;
    display: block;
  }
  .u-w80--laptop {
    width: 80%;
    display: block;
  }
  .u-w90--laptop {
    width: 90%;
    display: block;
  }
  .u-w100--laptop {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 1023px) {
  .u-w0--tab {
    width: 0%;
    display: block;
  }
  .u-w10--tab {
    width: 10%;
    display: block;
  }
  .u-w20--tab {
    width: 20%;
    display: block;
  }
  .u-w30--tab {
    width: 30%;
    display: block;
  }
  .u-w40--tab {
    width: 40%;
    display: block;
  }
  .u-w50--tab {
    width: 50%;
    display: block;
  }
  .u-w60--tab {
    width: 60%;
    display: block;
  }
  .u-w70--tab {
    width: 70%;
    display: block;
  }
  .u-w80--tab {
    width: 80%;
    display: block;
  }
  .u-w90--tab {
    width: 90%;
    display: block;
  }
  .u-w100--tab {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .u-w0--sp {
    width: 0%;
    display: block;
  }
  .u-w10--sp {
    width: 10%;
    display: block;
  }
  .u-w20--sp {
    width: 20%;
    display: block;
  }
  .u-w30--sp {
    width: 30%;
    display: block;
  }
  .u-w40--sp {
    width: 40%;
    display: block;
  }
  .u-w50--sp {
    width: 50%;
    display: block;
  }
  .u-w60--sp {
    width: 60%;
    display: block;
  }
  .u-w70--sp {
    width: 70%;
    display: block;
  }
  .u-w80--sp {
    width: 80%;
    display: block;
  }
  .u-w90--sp {
    width: 90%;
    display: block;
  }
  .u-w100--sp {
    width: 100%;
    display: block;
  }
}

.u-h100 {
  height: 100%;
}

.p-header {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.p-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-header-logo {
  text-align: center;
  font-size: 0;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .p-header-logo {
    padding: 15px 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-header-logo {
    padding: 20px 10px;
  }
}

.p-header-logo a {
  display: inline-block;
  line-height: 1;
  font-size: 0;
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .p-header-logo a {
    margin-right: 15px;
  }
}

.p-header-logo a img {
  width: auto;
  height: 25px;
}

@media screen and (max-width: 767px) {
  .p-header-logo a img {
    height: 18px;
  }
}

.p-header-logo p {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #0C8100;
}

@media screen and (max-width: 767px) {
  .p-header-logo p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1280px) {
  .p-gnav {
    display: none;
    background-color: #F5F5F5;
    position: absolute;
    right: 0;
    top: 0;
    padding: 80px 40px;
    max-width: 500px;
    width: 100%;
    height: 100vh;
  }
}

.p-gnav-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .p-gnav-list {
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
  }
}

.p-gnav-list-item {
  padding: 10px 20px;
}

@media screen and (max-width: 1280px) {
  .p-gnav-list-item {
    padding: 15px 0;
    width: 100%;
    text-align: left;
  }
}

.p-gnav-list-item a {
  font-size: 12px;
  letter-spacing: 0.05em;
  text-decoration: none;
  line-height: 1;
  font-weight: 400;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-gnav-list-item a {
    font-size: 14px;
  }
}

.p-gnav-list-item a::after {
  content: "";
  width: 0;
  height: 1px;
  background: linear-gradient(to right, #0c8100, #a9cb03);
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: all 0.5s;
}

.p-gnav-list-item.current a, .p-gnav-list-item a:hover {
  opacity: 1;
}

.p-gnav-list-item.current a::after, .p-gnav-list-item a:hover::after {
  width: 100%;
}

.p-gnav-list-item.career, .p-gnav-list-item.new {
  text-align: center;
  padding: 0;
}

@media screen and (max-width: 1280px) {
  .p-gnav-list-item.career, .p-gnav-list-item.new {
    width: 50%;
    margin-top: 40px;
  }
}

.p-gnav-list-item.career a, .p-gnav-list-item.new a {
  color: white;
  background: #a9cb03;
  padding: 20px;
  width: 160px;
  display: block;
  font-size: 10px;
}

@media screen and (max-width: 1280px) {
  .p-gnav-list-item.career a, .p-gnav-list-item.new a {
    width: 100%;
  }
}

.p-gnav-list-item.career a span, .p-gnav-list-item.new a span {
  font-family: 'EB Garamond', serif;
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
}

.p-gnav-list-item.career a::after, .p-gnav-list-item.new a::after {
  content: none;
}

.p-gnav-list-item.new {
  margin-left: 20px;
}

@media screen and (max-width: 1280px) {
  .p-gnav-list-item.new {
    margin-left: 0px;
    display: block;
  }
}

@media screen and (max-width: 1023px) {
  .p-gnav-list-item.new {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .p-gnav-list-item.career {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}

.p-gnav-list-item.career a {
  background: #0c8100;
}

.p-footer {
  padding: 40px 0 0;
  border-top: 1px solid #c5c5c5;
}

@media screen and (max-width: 767px) {
  .p-footer {
    padding: 40px 0 20px;
  }
}

.p-footer-logo {
  text-align: center;
  max-width: 200px;
  margin-bottom: 20px;
  margin-right: 60px;
  flex-basis: 200px;
}

@media screen and (max-width: 767px) {
  .p-footer-logo {
    width: 70%;
    margin: 0 auto;
  }
}

.p-footer-contents {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 0 40px;
}

@media screen and (max-width: 767px) {
  .p-footer-contents {
    display: block;
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .p-footer-contents .logo {
    width: 70%;
    margin: 0 auto;
  }
}

.p-footer-copyright {
  font-size: 12px;
  font-family: 'EB Garamond', serif;
  text-align: center;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .p-footer-copyright {
    font-size: 10px;
  }
}

.p-footer-nav {
  padding-bottom: 40px;
}

.p-footer-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 12px;
  margin: 0 -30px;
}

@media screen and (max-width: 767px) {
  .p-footer-nav ul {
    display: block;
    margin: 0;
  }
}

.p-footer-nav ul li {
  padding: 0 30px;
}

@media screen and (max-width: 767px) {
  .p-footer-nav ul li {
    padding: 10px 30px;
  }
}

.p-footer-nav ul li::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 3px solid black;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  padding-right: 10px;
}

.top-mv {
  position: relative;
}

.top-mv-inner {
  width: 100%;
  max-width: 1700px;
  padding: 0 170px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1280px) {
  .top-mv-inner {
    padding: 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .top-mv-inner {
    padding: 0;
  }
}

.top-mv-inner li {
  position: relative;
}

.top-mv-inner li .text {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 28px;
  letter-spacing: 0.1em;
  line-height: 2em;
  padding: 80px 0;
}

@media screen and (max-width: 1280px) {
  .top-mv-inner li .text {
    padding: 60px 0;
    font-size: 23px;
  }
}

@media screen and (max-width: 1023px) {
  .top-mv-inner li .text {
    -ms-writing-mode: horizontal-tb;
    writing-mode: horizontal-tb;
    padding: 0 40px 40px 0;
    font-size: 18px;
    position: unset;
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  .top-mv-inner li .text {
    padding: 20px 20px 40px;
    font-size: 15px;
  }
}

.top-mv-inner li .text span.en {
  font-family: 'EB Garamond', serif;
  font-size: 14px;
  display: block;
  border: none;
}

@media screen and (max-width: 1280px) {
  .top-mv-inner li .text span.en {
    font-size: 12px;
  }
}

.top-mv-inner li .text span {
  display: block;
  border-left: 1px solid #F5F5F5;
  font-family: 'EB Garamond', serif;
}

@media screen and (max-width: 1023px) {
  .top-mv-inner li .text span {
    border: none;
    border-bottom: 1px solid #F5F5F5;
  }
}

.top-mv-copy {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: fixed;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-family: 'EB Garamond', serif;
  text-align: center;
}

@media screen and (max-width: 1280px) {
  .top-mv-copy {
    right: 35px;
  }
}

@media screen and (max-width: 767px) {
  .top-mv-copy {
    font-size: 10px;
    right: 5px;
    z-index: 9999;
  }
}

.top-mv .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.top-mv .slick-dots {
  bottom: 15px;
}

@media screen and (max-width: 767px) {
  .top-mv .slick-dots {
    bottom: 20px;
  }
}

.top-mv .slick-dots li {
  width: 10px;
  height: 10px;
}

.top-mv .slick-dots li button {
  width: 10px;
  height: 10px;
}

.top-mv .slick-dots li button::before {
  width: 10px;
  height: 10px;
  background: white;
  content: '';
  opacity: 0.5;
}

.top-mv .slick-dots li.slick-active button:before {
  opacity: 1;
}

.top-news {
  background: white;
  position: absolute;
  bottom: 0;
  right: 170px;
  padding: 25px;
  display: flex;
  justify-content: center;
  font-size: 12px;
}

@media screen and (max-width: 1280px) {
  .top-news {
    right: 80px;
  }
}

@media screen and (max-width: 1023px) {
  .top-news {
    padding: 15px 25px;
  }
}

@media screen and (max-width: 767px) {
  .top-news {
    right: 0;
    bottom: -25px;
    font-size: 10px;
  }
}

.top-news .ttl {
  font-family: 'EB Garamond', serif;
  font-size: 16px;
  padding-right: 25px;
  border-right: 1px solid #ababab;
  margin-right: 25px;
}

@media screen and (max-width: 1023px) {
  .top-news .ttl {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .top-news .ttl {
    font-size: 12px;
    margin-right: 15px;
    padding-right: 15px;
  }
}

.top-news th {
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  margin-right: 10px;
}

.top-philosophy {
  margin-top: 0;
  background: url(../images/top/bg.jpg) no-repeat;
  background-size: 100%;
  background-position: bottom center;
  padding-bottom: 20%;
}

.top-recruit {
  position: relative;
  overflow-x: hidden;
}

.top-recruit .bg {
  position: absolute;
  width: 100%;
  height: 300px;
  z-index: -1;
  top: 0;
  background: linear-gradient(to right, #0c8100, #a9cb03);
}

@media screen and (max-width: 767px) {
  .top-recruit .bg {
    height: 250px;
  }
}

.top-recruit-list a {
  display: block;
  border: 1px solid #c5c5c5;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background: white;
}

.top-recruit-list a .img {
  width: 120px;
  height: 100%;
  background: #c5c5c5;
}

@media screen and (max-width: 767px) {
  .top-recruit-list a .img {
    width: 100%;
    height: 100px;
  }
}

.top-recruit-list a .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.top-recruit-list a .text {
  width: calc(100% - 120px);
  height: 100%;
  padding: 15px 20px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .top-recruit-list a .text {
    width: 100%;
    height: auto;
  }
}

.top-recruit-list a .text::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 1px solid #c5c5c5;
  border-right: 1px solid #c5c5c5;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%) rotate(45deg);
}

.top-recruit-list a .date {
  font-size: 12px;
  font-family: 'EB Garamond', serif;
  position: absolute;
  top: 0;
  left: 0;
}

.top-recruit-list a .date span {
  background: linear-gradient(to right, #0c8100, #a9cb03);
  font-size: 10px;
  color: white;
  display: inline-block;
  padding: 5px 10px;
}

.top-recruit-list a .ttl {
  margin-bottom: 5px;
}

.top-recruit-list a .category span {
  display: inline-block;
  background: #EEF5CD;
  padding: 2px 10px;
  border-radius: 20px;
  margin-bottom: 2px;
  line-height: 1em;
}

.top-other ul li a {
  display: block;
  padding: 50px 40px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .top-other ul li a {
    padding: 40px 30px;
  }
}

.top-other ul li a h2 {
  font-size: 26px;
  color: white;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .top-other ul li a h2 {
    font-size: 22px;
  }
}

.top-other ul li a::after {
  content: "";
  display: block;
  width: 80px;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: white;
}

@media screen and (max-width: 767px) {
  .top-other ul li a::after {
    width: 60px;
  }
}

.top-other ul li:nth-child(1) a {
  background: url(../images/top/company.jpg) no-repeat;
  background-size: cover;
}

.top-other ul li:nth-child(2) a {
  background: url(../images/top/q&a.jpg) no-repeat;
  background-size: cover;
}

.top-other ul li:nth-child(3) a {
  background: url(../images/top/welfare.jpg) no-repeat;
  background-size: cover;
}

.recruit-mv {
  background: linear-gradient(to right, #0c8100, #a9cb03);
  color: white;
  text-align: center;
  padding: 120px 0;
}

.recruit-nav {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

@media screen and (max-width: 767px) {
  .recruit-nav {
    margin: -5px;
  }
}

.recruit-nav li {
  padding: 10px;
  width: calc(100%/3);
}

@media screen and (max-width: 767px) {
  .recruit-nav li {
    width: calc(100%);
    padding: 5px;
  }
}

.recruit-nav li a {
  padding: 20px;
  background: #a9cb03;
  color: white;
  display: block;
  text-align: center;
  position: relative;
}

.recruit-nav li a::before {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-right: 1px solid;
  border-top: 1px solid;
  transform: translateY(-50%) rotate(45deg);
  position: absolute;
  top: 50%;
  right: 20px;
}

.recruit-nav li:nth-child(2) a {
  background: #5BA602;
}

.recruit-nav li:nth-child(3) a {
  background: #0c8100;
}

.recruit-list a {
  display: block;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background: #F5F5F5;
  padding: 30px;
}

@media screen and (max-width: 767px) {
  .recruit-list a {
    padding: 0;
  }
}

.recruit-list a .img {
  width: 180px;
  height: 100%;
  background: #c5c5c5;
}

@media screen and (max-width: 767px) {
  .recruit-list a .img {
    width: 100%;
    height: 100px;
  }
}

.recruit-list a .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.recruit-list a .text {
  width: calc(100% - 180px);
  height: 100%;
  padding: 0 30px;
}

@media screen and (max-width: 767px) {
  .recruit-list a .text {
    width: 100%;
    height: auto;
    padding: 20px;
    padding-bottom: 40px;
  }
}

.recruit-list a .ttl {
  margin-bottom: 5px;
}

.recruit-list a .category span {
  display: inline-block;
  background: #EEF5CD;
  padding: 2px 10px;
  border-radius: 20px;
  margin-bottom: 2px;
  line-height: 1em;
}

.recruit-list a .more {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 14px;
  font-family: 'EB Garamond', serif;
  background: #a9cb03;
  color: white;
  padding: 10px 40px;
}

.recruit-list a .more.career {
  background: #5BA602;
}

.recruit-list a .more.part {
  background: #0c8100;
}

.recruit .category span {
  display: inline-block;
  background: #EEF5CD;
  padding: 2px 10px;
  border-radius: 20px;
  margin-bottom: 2px;
  line-height: 1em;
}

.recruit .img-thum {
  height: 100%;
  width: 100%;
  background: gray;
}

.recruit .img-thum img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.recruit .img-yoko {
  height: 100px;
  width: 100%;
  background: gray;
}

.recruit .img-yoko img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.philosophy-bg {
  background: url(../images/philosophy/bg.jpg) no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding-bottom: 350px;
}

@media screen and (max-width: 1023px) {
  .philosophy-bg {
    padding-bottom: 250px;
  }
}

@media screen and (max-width: 767px) {
  .philosophy-bg {
    padding-bottom: 100px;
  }
}

.message-line {
  position: relative;
}

.message-line::before {
  content: "";
  display: block;
  width: 1px;
  height: 130px;
  background: black;
  position: absolute;
  top: -130px;
  left: 80px;
}

@media screen and (max-width: 1023px) {
  .message-line::before {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 767px) {
  .message-line::before {
    height: 50px;
    top: -70px;
  }
}

.about-line {
  position: relative;
}

.about-line::before {
  content: "";
  display: block;
  width: 1px;
  height: 130px;
  background: black;
  position: absolute;
  top: -160px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  .about-line::before {
    height: 50px;
    top: -70px;
  }
}

.about-nav {
  display: flex;
  flex-wrap: wrap;
}

.about-nav li {
  padding: 5px;
  width: calc(100%/4);
}

@media screen and (max-width: 1023px) {
  .about-nav li {
    width: calc(100%/2);
  }
}

@media screen and (max-width: 767px) {
  .about-nav li {
    width: calc(100%/1);
  }
}

.about-nav li a {
  display: block;
  background: #F5F5F5;
  text-align: center;
  padding: 15px;
  position: relative;
  font-size: 14px;
}

.about-nav li a::before {
  content: "";
  display: block;
  height: 5px;
  width: 5px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  position: absolute;
  top: 45%;
  right: 20px;
  transform: rotate(45deg);
}

.about-nav li a:hover {
  opacity: 1;
  background: #0c8100;
  color: white;
}

.about-nav li a:hover::before {
  border-color: white;
}

.about-messege {
  position: relative;
  border-radius: 5px;
  margin-top: 30px;
}

.about-messege::before {
  content: "担当者の声";
  background: white;
  border-radius: 5px;
  padding: 5px 15px;
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 14px;
}

.about .slick-dotted.slick-slider {
  margin-bottom: 15px;
}

.about .slick-dots {
  bottom: -15px;
}

.about .slick-dots li {
  width: 30px;
  height: 5px;
}

.about .slick-dots li button {
  width: 30px;
  height: 5px;
}

.about .slick-dots li button::before {
  width: 30px;
  height: 5px;
  content: '';
  background: black;
}

.flow-list {
  width: 100%;
}

.flow-list tr {
  margin-bottom: 5px;
  background: #F5F5F5;
  padding: 15px;
  display: flex;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .flow-list tr {
    font-size: 14px;
    padding: 15px;
  }
}

.flow-list tr th {
  font-family: 'EB Garamond', serif;
  margin-right: 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.flow-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flow-nav li {
  padding: 5px;
  width: calc(100%/4);
}

@media screen and (max-width: 1023px) {
  .flow-nav li {
    width: calc(100%/2);
  }
}

@media screen and (max-width: 767px) {
  .flow-nav li {
    width: calc(100%/1);
  }
}

.flow-nav li a {
  display: block;
  background: #F5F5F5;
  text-align: center;
  padding: 15px;
  position: relative;
  font-size: 14px;
}

.flow-nav li a::before {
  content: "";
  display: block;
  height: 5px;
  width: 5px;
  border-top: 1px solid black;
  border-right: 1px solid black;
  position: absolute;
  top: 45%;
  right: 20px;
  transform: rotate(45deg);
}

.flow-nav li a:hover {
  opacity: 1;
  background: #0c8100;
  color: white;
}

.flow-nav li a:hover::before {
  border-color: white;
}

.flow-nav li a.current {
  background: #0c8100;
  color: white;
}

.flow-nav li a.current::before {
  border-color: white;
}

.flow-schedule {
  margin: 0;
  display: block;
  position: relative;
  height: 100%;
}

.flow-schedule::before {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: linear-gradient(to top, #0c8100, #a9cb03);
  position: absolute;
  top: 0;
  left: 100px;
}

@media screen and (max-width: 767px) {
  .flow-schedule::before {
    left: 60px;
  }
}

.flow-schedule tbody {
  width: 100%;
  display: block;
}

.flow-schedule tr {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.flow-schedule tr:nth-of-type(odd) {
  background-color: #EEF5CD;
}

.flow-schedule tr th {
  width: 120px;
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  position: relative;
}

@media screen and (max-width: 767px) {
  .flow-schedule tr th {
    width: 80px;
  }
}

.flow-schedule tr th::before {
  content: "●";
  display: block;
  color: #0c8100;
  position: absolute;
  top: -7px;
  right: 10px;
}

.flow-schedule tr th span {
  position: absolute;
  top: -7px;
  left: 40px;
}

@media screen and (max-width: 767px) {
  .flow-schedule tr th span {
    left: 10px;
  }
}

.flow-schedule tr td {
  width: calc(100% - 120px);
  padding: 15px;
}

@media screen and (max-width: 767px) {
  .flow-schedule tr td {
    width: calc(100% - 80px);
    padding: 15px 10px;
  }
}

.faq-list-item {
  margin: 10px;
  border-bottom: 1px solid #ccc;
  position: relative;
  cursor: pointer;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .faq-list-item {
    margin: 15px 10px;
  }
}

.faq-list-item h3 {
  padding: 15px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .faq-list-item h3 {
    padding: 10px 15px;
    line-height: 1.3em;
  }
}

.faq-list-item h3 span {
  font-family: 'EB Garamond', serif;
  margin-right: 10px;
}

.faq-list-item .icon {
  position: absolute;
  top: 10px;
  right: 5px;
  color: #ccc;
  font-size: 18px;
}

.faq .answer {
  padding: 5px 0px;
  margin-bottom: 15px;
  display: none;
}

.faq .answer p {
  font-size: 14px;
}

.company-road {
  margin: 0;
  display: block;
  position: relative;
  height: 100%;
}

.company-road tbody {
  width: 100%;
  display: block;
}

.company-road tr {
  width: 100%;
}

.company-road tr th {
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  position: relative;
  padding: 15px;
  width: 200px;
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .company-road tr th {
    width: 80px;
  }
}

.company-road tr th span {
  position: absolute;
  top: -7px;
  left: 40px;
}

@media screen and (max-width: 767px) {
  .company-road tr th span {
    left: 10px;
  }
}

.company-road tr td {
  padding: 15px;
  width: calc(100% - 270px);
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .company-road tr td {
    width: calc(100% - 80px);
    padding: 15px 10px;
  }
}

.company-road tr td:nth-last-child(2) {
  position: relative;
  padding-left: 30px;
  font-family: 'EB Garamond', serif;
  font-weight: normal;
  width: 70px;
}

.company-road tr td:nth-last-child(2)::before {
  content: "●";
  display: block;
  color: #0c8100;
  position: absolute;
  left: 0;
  z-index: +1;
}

.company-road tr td:nth-last-child(2)::after {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
  position: absolute;
  top: 15px;
  left: 7px;
}

@media screen and (max-width: 767px) {
  .company-road tr td:nth-last-child(2)::after {
    left: 60px;
  }
}

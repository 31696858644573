/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: auto;
  padding: 15px 55px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-black;
  background-color: $color-white;
  border: 1px solid $color-gray;
  position: relative;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &--arrow {
    width: 100%;
    max-width: 400px;
    text-align: left;
    padding: 20px 30px;
    font-size: 14px;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        width: 30px;
        height: 1px;
        background: $color-gray;
        -webkit-transition: all .3s;
        transition: all .3s;
    }
    &::after {
      content: '';
      position: absolute;
      top: 46%;
      right: 18px;
      width: 12px;
      height: 1px;
      background: $color-gray;
      -webkit-transform: rotate(35deg);
      transform: rotate(35deg);
      -webkit-transition: all .3s;
      transition: all .3s;
    }
    &:hover {
      background: $color-gray;
      color: white;
      opacity: 1;
      &::after,&::before {
        background: white;
      }
    }

    &-left {
      text-align: center;
      &::before {
        left: 20px;
      }
      &::after {
        left: 18px;
        transform: rotate(-35deg);
      }
    }

    &.green {
      background: $color-green;
      color: white;
      border-color: $color-green;
      padding: 30px;
      max-width: 500px;
      &::before, &::after {
        background: $color-white;
      }
      &:hover {
        opacity: 0.6;
      }
      &-light {
        background: $color-green-light;
        color: white;
        border-color: $color-green-light;
        padding: 30px;
        max-width: 500px;
        &::before, &::after {
          background: $color-white;
        }
        &:hover {
          opacity: 0.6;
        }
      }
      &2 {
        background: #5BA602;
        color: white;
        border-color: #5BA602;
        padding: 30px;
        max-width: 500px;
        &::before, &::after {
          background: $color-white;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &--contact {
    background: white;
    padding: 20px 30px;
    font-size: 14px;
    width: 50%;
    max-width: 400px;
    text-align: center;

    @include view-at(sp) {
      padding: 15px;
      width: 90%;
      display: block;
      margin: 0 auto;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;
  }

  &--large {
    padding: 20px;
    font-size: nth($font-size, 3);
    min-width: 200px;
  }

  &--text {
    font-size: 14px;
    font-family: 'EB Garamond', serif;
  }

  &--tel {
    background: white;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    font-family: 'EB Garamond', serif;
    font-size: 25px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: inline-block;
      width: 35px;
      height: 23px;
      margin-right: 10px;
      background: url(../images/common/icon-tel.png) no-repeat;
      background-size: auto 100%;
      background-position: center;
    }
  }

  &--form {
    background: white;
    padding: 20px 30px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    font-family: 'EB Garamond', serif;
    font-size: 16px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: inline-block;
      width: 35px;
      height: 23px;
      margin-right: 10px;
      background: url(../images/common/icon-form.png) no-repeat;
      background-size: auto 100%;
      background-position: center;
    }
  }

  &--link {
    position: relative;
    border-color: $color-green;
    font-size: 14px;
    &::after {
      content: "";
      width: 5px;
      height: 5px;
      border-right: 1px solid $color-green;
      border-bottom: 1px solid $color-green;
      display: block;
      transform: translateY(-50%) rotate(-45deg);
      position: absolute;
      top: 50%;
      right: 10px;
    }
  }

  &--next {
    position: relative;
    border-color: $color-green;
    background: $color-green;
    color: white;
    font-size: 14px;
    &::after {
      content: "";
      width: 5px;
      height: 5px;
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      display: block;
      transform: translateY(-50%) rotate(-45deg);
      position: absolute;
      top: 50%;
      right: 10px;
    }
  }
}

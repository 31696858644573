.p-header {
  background-color: rgba($color: $color-white, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  // box-shadow: 0 0 2px rgba(0,0,0,0.4);

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-logo {
    text-align: center;
    font-size: 0;
    padding-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include view-at(laptop) {
      padding: 15px 20px;
    }
    @include view-at(sp) {
      padding: 20px 10px;
    }

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;
      margin-right: 30px;
      @include view-at(sp) {
        margin-right: 15px;
      }

      img {
        width: auto;
        height: 25px;
        @include view-at(sp) {
          height: 18px;
        }
      }
    }
    p {
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
      font-weight: bold;
      font-size: 20px;
      color: #0C8100;
      @include view-at(sp) {
        font-size: 14px;
      }
    }
  }
}

/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);

  thead {
    tr {
      @include view-at(sp) {
        border-bottom: 0;
      }
    }
    th {
      padding: 25px;
      line-height: 1.5;
      font-weight: $bold;
      width: 30%;
      background-color: $color-gray-snow;
      vertical-align: top;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    td {
      font-weight: $bold;
      text-align: center;
      padding: 20px 30px;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
      }
    }
  }

  tbody {
    tr {
      @include view-at(sp) {
        display: block;
        border-bottom: 0;
        margin-bottom: 10px;
      }
    }
    th {
      padding: 25px;
      line-height: 1.5;
      font-weight: $bold;
      width: 25%;
      // background-color: $color-gray-snow;
      vertical-align: top;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
        padding: 10px;
        min-height: auto;
        font-weight: bold;
        font-size: 15px;
      }
    }
    td {
      padding: 20px 30px;
      border-bottom: 1px solid $color-gray-light;

      @include view-at(sp) {
        display: block;
        width: 100%;
        padding: 10px;
        border: none;
        font-size: 14px;
      }
    }
  }

  &--narrow {
    border-color: $color-gray-light;
    tbody {
      tr {
        border-color: $color-gray-light;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }

  &--welfare {
    border: 1px solid $color-gray-light;
    tr {
      @include view-at(sp) {
        margin: 0 !important;
        border-bottom: 1px solid $color-gray-light !important;
        &:nth-last-child(1) {
          border-bottom: none !important;
        }
      }
      th {
        padding: 10px;
        background: $color-green-snow;
        border-right: 1px solid $color-gray-light;
        @include view-at(sp) {
          border-right: none;
          padding: 15px;
          text-align: center;
        }
      }
      td {
        padding: 10px;
        &:nth-last-child(2) {
          background: $color-gray-snow;
          border-right: 1px solid $color-gray-light;
          @include view-at(sp) {
            border-right: none;
          }
        }
      }
    }
  }

  &--entry {
    tr {
      th {
        width: 250px;
        border-bottom: none;
        @include view-at(sp) {
          padding-bottom: 0;
        }
        span {
          font-size: 60%;
          color: red;
          vertical-align: inherit;
          padding-left: 5px;
        }
      }
      td {
        border-bottom: none;
      }
    }
  }
}

/* --------------------------
見出し
-------------------------- */



.c-h1 {
  margin-top: 190px;
  color: $color-black;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
    padding: 15px;
    margin-top: 110px;
  }

  small {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
  }
}

.c-h2 {
  font-family: 'EB Garamond', serif;
  margin: 0 auto 40px;
  font-size: 35px;
  @include view-at(tab) {
    font-size: 25px;
    margin-bottom: 20px;
  }
  span {
    font-size: 12px;
    margin-top: 10px;
    display: block;
  }
  &-text {
    font-size: 28px;
    margin-bottom: 40px;
    line-height: 2;
    @include view-at(tab) {
      font-size: 18px;
      line-height: 2em;
      margin-bottom: 20px;
    }
  }
}

.c-h3 {
  font-size: 20px;
  line-height: 1.8em;
  letter-spacing: 0.08em;
  font-weight: $normal;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: 16px;
    margin-bottom: 10px;
  }

  &--border {
    border-bottom: 1px solid $color-gray-light;
    padding-bottom: 10px;
  }
  
  &--dot {
    display: flex;
    align-items: center;
    line-height: 1.3em;
    &::before {
      content: "●";
      color: $color-green;
      font-size: 80%;
      margin-right: 5px;
      display: inline-block;
    }
  }
}

.c-h4 {
  font-size: nth($font-size, 3);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 20px;
  &--border {
    border-left: 5px solid $color-green;
    padding-left: 10px;
  }
  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
  }
}

.c-h5 {
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: 0.08em;
  font-weight: bold;
  margin-bottom: 10px;

  @include view-at(sp) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.c-h6 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}
/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-gray-snow;

  &--border {
    border: 1px solid $color-gray-light;
    background-color: $color-white;
  }

  &--white {
    background: white;
  }

  &-contents {
    padding: 40px;

    @include view-at(sp) {
      padding: 20px;
    }
    &--middle {
      padding: 20px;
    }
    &--narrow {
      padding: 15px;
    }
  }
}
